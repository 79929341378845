























































































































































import {InstructsLiteRelation} from '@/models/instructs.relation';
import {NotionLiteNode} from '@/models/notion.node';
import {
  playerFetchQuery,
  playerFetchTrainedForTrainingNotionsQuery,
  PlayerNode,
  PlayerTrainedForTrainingsNotionsNode,
  playerUpdateQuery
} from '@/models/player.node';
import {TrainedForNotionsRelation} from '@/models/trainedFor.relation';
import {TrainingNotionsNode} from '@/models/training.node';
import {isPlayerMasteringNotion} from '@/services/exercise.service';
import {Localization, localizationQuery} from '@/services/localization.service';
import UserService from '@/services/user.service';
import {flatten} from 'lodash';
import {Component, Vue} from 'vue-property-decorator';


@Component({
  name: 'Profile',
  components: {},
  apollo: {
    player: {
      query: playerFetchQuery,
      update: data => data?.players?.length ? data?.players[0] : undefined,
      fetchPolicy: 'cache-first',
    },
    localizations: {
      query: localizationQuery,
      fetchPolicy: 'cache-only',
    },
    notions: {
      query: playerFetchTrainedForTrainingNotionsQuery,
      // query: notionsLiteFetchAllQuery,
      fetchPolicy: 'cache-first',
      update: data => {
        if (!data?.players?.length) return [];

        const playerData: PlayerTrainedForTrainingsNotionsNode = data?.players[0];
        const relTrainedForTraining: TrainedForNotionsRelation[] = playerData.relTrainedForTrainingConnection.edges;
        const trainingsData: TrainingNotionsNode[] = relTrainedForTraining.map(rel => rel.node)
        if (!trainingsData?.length) return [];

        const relInstructsNotions: InstructsLiteRelation[] = flatten(trainingsData.map(training => training.relInstructsNotionsConnection.edges));
        if (!relInstructsNotions?.length) return [];

        const instructedNotions: NotionLiteNode[] = relInstructsNotions.map(rel => rel.node);
        if (!instructedNotions.length) return [];

        return instructedNotions;
      }
    },
  },
})
export default class Profile extends Vue {
  player: PlayerNode | undefined;
  localizations: Localization | undefined;
  notions: NotionLiteNode[] = [];

  editPseudo = false;

  // get allTaskedForFinishedForCurrentPlayer(): RelTaskedForModel[] {
  //   return this.player ? QuestService.getAllRelTaskedForFinishedForCurrentPlayer(this.player) : [];
  // }

  get hoursSpend() {
    return this.player ? Math.floor(this.player.gameTimeSpent / (1000 * 60 * 60)) : 0;
  }


  get minutesSpend() {
    return this.player ? Math.floor((this.player.gameTimeSpent / (1000 * 60)) % 60) : 0;
  }


  get masteredNotions(): NotionLiteNode[] {
    return this.player && this.notions ?
        this.notions.filter(notion => isPlayerMasteringNotion(this.player as PlayerNode, notion)) : [];
  }


  // get xpProgress() {
  //   return this.player ? this.player.currentXP / GameService.getRequiredLevelXP() * 100 : 0;
  // }


  mounted() {
    this.$root.$emit('navigationEvent', {showBtnDisconnect: true, color: 'secondary', title: this.$loc('Profile.title')});

    // setTimeout(() => {
    //   console.debug(0, 'Profile : player', cloneDeep(this.player));
    // }, 2000);
  }


  destroyed() {
    this.$root.$emit('navigationEvent', {showBtnDisconnect: false});
  }


  isMasteredNotion(_notion: NotionLiteNode): boolean {
    return this.masteredNotions.some(notion => notion._id === _notion._id);
  }


  async savePlayer(): Promise<any> {
    this.editPseudo = false;

    if (this.player) {
      const playerUpdateInput = {
        pseudo: this.player.pseudo,
      };

      return this.$apollo.mutate({
        mutation: playerUpdateQuery,
        variables: {
          playerId: this.player._id,
          playerUpdateInput
        }
      });
    }
  }


  disconnect = () => UserService.signOut();
}

import {ChapterNode} from '@/models/chapter.node';
import {endedUpdateOrCreateByIdsQuery} from '@/models/ended.relation';
import {ExerciseNode} from '@/models/exercise.node';
import {KnowsRelation, knowsUpdateOrCreateByIdsQuery} from '@/models/knows.relation';
import {MustAchieveRelation} from '@/models/mustAchieve.relation';
import {MustPlayRelation} from '@/models/mustPlay.relation';
import {NotionLiteNode, NotionNode} from '@/models/notion.node';
import {PlayerNode, playerUpdateQuery} from '@/models/player.node';
import {TestsRelation} from '@/models/tests.relation';
import {TookRelation, tookUpdateOrCreateByIdsQuery} from '@/models/took.relation';
import {TrainingChaptersNode} from '@/models/training.node';
import {VueService} from '@/services/vue.service';
import {NOTION_PLAYER_MASTERING_REQUIRED_XP} from '@/variables';
import {flatten, sortBy, uniqBy} from 'lodash';


export function getTrainingExercises(_training: TrainingChaptersNode): ExerciseNode[] {
    const chapters = _training.relMustPlayChaptersConnection.edges.map((mustPlayRel: MustPlayRelation) => mustPlayRel.node);
    const exercises: ExerciseNode[] = [];
    chapters.forEach((chapter: ChapterNode) => {
            chapter.relMustAchieveExercisesConnection.edges.forEach((mustAchieveRel: MustAchieveRelation) => exercises.push(mustAchieveRel.node));
        }
    );
    return exercises;
}


export function getFirstTrainingExerciseTookRelation(_player: PlayerNode, _training: TrainingChaptersNode): TookRelation | undefined {
    const tookRels: TookRelation[] = sortBy(_player.relTookExerciseConnection.edges, '_createdAt');
    const exercises: ExerciseNode[] = getTrainingExercises(_training);
    return tookRels.find(tookRel => exercises.some(exercise => exercise._id = tookRel.node._id));
}


export function getChapterMustAchieveExercicesWhereNotTookRelations(_player: PlayerNode, _chapter: ChapterNode): MustAchieveRelation[] {
    const tookRels = _player.relTookExerciseConnection.edges;
    const mustAchieveRels = _chapter.relMustAchieveExercisesConnection?.edges?.filter((mustAchieveRel: MustAchieveRelation) =>
        !tookRels.some(tookRel => tookRel.node._id === mustAchieveRel.node._id)
    );
    return mustAchieveRels;
}


export function getChapterTeachesNotionTestsExercices(_player: PlayerNode, _chapter: ChapterNode): TestsRelation[] {
    const chapterNotions: NotionNode[] = _chapter.relTeachesNotionsConnection.edges.map(rel => rel.node);
    const testsRels: TestsRelation[] = flatten(chapterNotions.map(notion => notion.relTestsExercisesConnection.edges));

    return uniqBy(testsRels, '_id');
}


export function getChapterTeachesNotionTestsExercicesWhereNotTookRelations(_player: PlayerNode, _chapter: ChapterNode): TestsRelation[] {
    const testsRels: TestsRelation[] = getChapterTeachesNotionTestsExercices(_player, _chapter);

    const tookRels: TookRelation[] = _player.relTookExerciseConnection.edges;
    const filteredTestsRels: TestsRelation[] = testsRels.filter((testsRel: TestsRelation) =>
        !tookRels.some(tookRel => tookRel.node._id === testsRel.node._id)
    )

    return filteredTestsRels;
}


export function isPlayerMasteringNotion(_player: PlayerNode, _notion: NotionLiteNode): boolean {
    if (!!_player?.relKnowsNotionsConnection?.edges) {
        const relKnowsNotion = _player.relKnowsNotionsConnection.edges.find(relationKnows => relationKnows.node._id === _notion._id);
        return !!relKnowsNotion && relKnowsNotion.xp > NOTION_PLAYER_MASTERING_REQUIRED_XP;
    }
    else return false;
}


class ExerciseService extends VueService {
    constructor() {
        super();

        console.debug(1, 'ExerciseService : constructor');
    }


    async exerciseCompleted(_player: PlayerNode, _notions: NotionNode[], _exercise: ExerciseNode, _succeed: boolean): Promise<any> {
        const promises = [
            // Update player.lastExerciseDate
            this.$vue.$apollo.mutate({
                mutation: playerUpdateQuery,
                variables: {
                    playerId: _player._id,
                    playerUpdateInput: {
                        lastExerciseDate: new Date()
                    }
                }
            }),
            // Update or create TOOK relation
            this.$vue.$apollo.mutate({
                mutation: tookUpdateOrCreateByIdsQuery,
                variables: {
                    playerId: _player._id,
                    exerciseId: _exercise._id,
                    relCreateInput: {
                        succeed: _succeed
                    },
                },
                // optimisticResponse: {
                //     updatePlayers: {
                //         __typename: 'UpdatePlayersMutationResponse',
                //         players: [
                //             merge(_player,
                //                 {
                //                     relTookExerciseConnection: {
                //                         __typename: 'PlayerRelTookExerciseConnection',
                //                         _id: 'temp-id',
                //                         edges: [
                //                             {
                //                                 __typename: 'PlayerRelTookExerciseRelationship',
                //                                 _id: 'temp-id',
                //                                 _ownerId: null,
                //                                 _createdAt: (new Date()).toISOString(),
                //                                 _updatedAt: null,
                //                                 succeed: _succeed,
                //                                 node: _exercise
                //                             }
                //                         ],
                //                     },
                //                 }
                //             )
                //         ],
                //     }
                // },
            })

        ];

        // Update or create KNOWS relation
        if (_succeed) {
            for (const notion of _notions) {
                const currentRelKnows: KnowsRelation | undefined = _player.relKnowsNotionsConnection.edges.find((_rel: KnowsRelation) => _rel.node._id === notion._id);

                promises.push(
                    this.$vue.$apollo.mutate({
                        mutation: knowsUpdateOrCreateByIdsQuery,
                        variables: {
                            playerId: _player._id,
                            notionId: notion._id,
                            relCreateInput: {
                                xp: currentRelKnows ? currentRelKnows.xp + _exercise.xp : _exercise.xp
                            },
                        },
                    })
                )
            }
        }

        return Promise.all(promises);
    }


    async chapterCompleted(_player: PlayerNode, _chapter: ChapterNode): Promise<any> {
        return this.$vue.$apollo.mutate({
            mutation: endedUpdateOrCreateByIdsQuery,
            variables: {
                playerId: _player._id,
                chapterId: _chapter._id,
                relCreateInput: {},
            }
        });
    }
}

export default new ExerciseService();

import Vue from 'vue';

export type VueExtended = Vue & {
    $loc: Function
    $sync: Function
    $navigate: Function
    $scrollToElement: Function
}


export class VueService {
    // @ts-ignores
    $vue: VueExtended;

    // $states: {
    //     player?: PlayerNode,
    //     training?: TrainingNode,
    // } = {};

    constructor() {
        console.debug(1, 'VueService : constructor');
    }

    static criticalError(err: any) {
        console.error('criticalError', err);
        // TODO : display to player
    }
}

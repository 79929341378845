








































import {Component, Prop, Vue} from 'vue-property-decorator';


@Component({
  name: 'SessionScore',
  components: {},
})
export default class SessionScore extends Vue {
  @Prop({required: true}) sessionXp !: number;
  @Prop({required: true}) sessionScore !: number;
}

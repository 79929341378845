import {ChapterNode} from '@/models/chapter.node';
import {ExerciseNode} from '@/models/exercise.node';
import {MustPlayRelation} from '@/models/mustPlay.relation';
import {mustTrainFetchAllQuery, MustTrainRelation} from '@/models/mustTrain.relation';
import {NotionNode} from '@/models/notion.node';
import {playerFetchLocalizationQuery, playerFetchQuery, playerFetchTrainingQuery, PlayerNode} from '@/models/player.node';
import {TrainedForRelation} from '@/models/trainedFor.relation';
import {trainingFetchQuery, TrainingLiteNode, TrainingChaptersNode} from '@/models/training.node';
import {cache} from '@/services/apollo.service';
import {Localization, localizationQuery, LocalizationService} from '@/services/localization.service';
import {FirebaseUserModel, UserModel} from '@/services/user.service';
import {VueService} from '@/services/vue.service';
import {ApolloQueryResult} from 'apollo-client';
import {Subscription} from 'apollo-client/util/Observable';
import {getAuth} from 'firebase/auth';
import {cloneDeep, isObject, uniqBy} from 'lodash';


class StoreService extends VueService {

    readonly states: {
        user?: UserModel,

        player?: PlayerNode,

        relTrainedFor?: TrainedForRelation;
        training?: TrainingChaptersNode,
        relMustPlay?: MustPlayRelation;
        chapter?: ChapterNode;
        // relMustAccomplish?: RelMustAccomplishModel;
        // quest?: QuestModel;
        // relMustDo?: RelMustDoModel;
        // task?: TaskModel;

        localizations: Localization[];

        exercise?: ExerciseNode;
        notion?: NotionNode;

        // playerTrainingNotions?: NotionNode[];
    } = {
        localizations: []
    };

    previousStates: {
        player?: PlayerNode,
        training?: TrainingChaptersNode,
        chapter?: ChapterNode,
        localization?: any,
    } = {};

    private playerFetchQuerySubscription?: Subscription;
    private trainingFetchQuerySubscription?: Subscription;
    private chapterFetchQuerySubscription?: Subscription;
    private localizationFetchQuerySubscription?: Subscription;
    private exerciseFetchQuerySubscription?: Subscription;


    constructor() {
        super();
        console.debug(1, 'StoreService : constructor');
    }


    async initialize() {
        await this.onLocalizationStateChange();
    }


    public onUserStateChange(_user: UserModel): void {
        console.debug(1, 'StoreService : onUserStateChange', _user);

        // State init : user
        this.states.user = _user;

        cache.writeData(
            {
                data: {
                    user: Object.assign(_user, {
                        __typename: 'User',
                        _id: 'User:1',
                    })
                }
            }
        );

        // State init : player
        this.setPlayerFetchQuerySubscription();
        this.setLocalizationFetchQuerySubscription();
        this.setTrainingFetchQuerySubscription();
        this.setExerciseFetchQuerySubscription();
        // this.setPlayerTrainingNotionsFetchQuerySubscription();

        console.debug(0, cloneDeep(this.states));
    }


    setPlayerFetchQuerySubscription() {
        console.debug(1, 'StoreService : setPlayerFetchQuerySubscription');

        if (this.playerFetchQuerySubscription) this.playerFetchQuerySubscription.unsubscribe();
        const email = getAuth().currentUser?.email;

        if (email) {
            this.playerFetchQuerySubscription = this.$vue.$apollo.watchQuery({
                query: playerFetchQuery,
                variables: {email}
            })
                .subscribe((_result) => this.onPlayerStateChange(_result));
        }
    }


    setTrainingFetchQuerySubscription() {
        console.debug(1, 'StoreService : setTrainingFetchQuerySubscription');

        if (this.trainingFetchQuerySubscription) this.trainingFetchQuerySubscription.unsubscribe();

        // Watch for player.currentTrainingId change
        this.trainingFetchQuerySubscription = this.$vue.$apollo.watchQuery({
            query: playerFetchTrainingQuery,
            fetchPolicy: 'cache-only',
        })
            .subscribe((_result) => {
                console.debug(0, 'StoreService : setTrainingFetchQuerySubscription', _result);

                if (!_result?.data?.players) return console.debug(0, 'StoreService : setTrainingFetchQuerySubscription : player data are empty');

                const player: PlayerNode = _result?.data?.players[0];
                const currentTrainingId = player?.currentTrainingId || this.states.player?.currentTrainingId;
                console.debug(0, 'StoreService : trainingFetchQuerySubscription', currentTrainingId, _result, cloneDeep(this.states));

                if (currentTrainingId) {
                    // update training
                    this.$vue.$apollo.query({
                        query: trainingFetchQuery,
                        variables: {
                            trainingId: currentTrainingId,
                        },
                        // fetchPolicy: 'cache-first',
                    })
                        .then((_result) => {
                            const training: TrainingChaptersNode = _result.data.trainings[0];
                            this.onTrainingStateChange(training)
                        })
                }
            });
    }


    setLocalizationFetchQuerySubscription() {
        console.debug(1, 'StoreService : setLocalizationFetchQuerySubscription');

        if (this.localizationFetchQuerySubscription) this.localizationFetchQuerySubscription.unsubscribe();

        this.localizationFetchQuerySubscription = this.$vue.$apollo.watchQuery({
            query: playerFetchLocalizationQuery,
            fetchPolicy: 'cache-only',
        })
            .subscribe(async (_result) => {
                if (!_result?.data?.players) return console.debug(0, 'StoreService : setLocalizationFetchQuerySubscription : player data are empty');
                const player: PlayerNode = _result?.data?.players[0];
                await this.onLocalizationStateChange(player);
            });
    }


    setExerciseFetchQuerySubscription() {
        console.debug(1, 'StoreService : setExerciseFetchQuerySubscription');

        if (this.exerciseFetchQuerySubscription) this.exerciseFetchQuerySubscription.unsubscribe();

        this.exerciseFetchQuerySubscription = this.$vue.$apollo.watchQuery({
            query: mustTrainFetchAllQuery,
            fetchPolicy: 'network-only', // TODO
        })
            .subscribe((_result) => this.onExerciseStateChange(_result));
    }


    private onPlayerStateChange(result: ApolloQueryResult<any>) {
        console.debug(1, 'StoreService : onPlayerStateChange', result.data);

        const player = result.data.players[0];
        if (!player) return;

        this.previousStates.player = cloneDeep(this.states.player)
        this.states.player = result.data.players[0];

        console.debug(0, 'StoreService : onPlayerStateChange : states', cloneDeep(this.states));
    }


    private onTrainingStateChange(_training: TrainingChaptersNode) {
        console.debug(1, 'StoreService : onTrainingStateChange', _training, cloneDeep(this.states));

        this.previousStates.training = cloneDeep(this.states.training);
        this.states.training = _training;
        this.states.relTrainedFor = this.states.player?.relTrainedForTrainingConnection.edges.find(_rel => _rel.node._id === _training._id);

        // this.onLocalizationStateChange();

        // const currentChapterId = player?.currentChapterId || this.states.player?.currentChapterId;
        this.onChapterStateChange();
        this.setExerciseFetchQuerySubscription();

        console.debug(0, 'StoreService : onTrainingStateChange : states', cloneDeep(this.states));
    }


    private onChapterStateChange() {
        const mustPlayChapterRelations = this.states.training?.relMustPlayChaptersConnection.edges;
        const mustPlayChapterRel = mustPlayChapterRelations?.find(_rel => _rel.node._id === this.states.player?.currentChapterId);

        if (mustPlayChapterRel) {
            this.states.relMustPlay = mustPlayChapterRel;
            this.states.chapter = mustPlayChapterRel.node;

            cache.writeData({
                data: {
                    currentRelMustPlay: mustPlayChapterRel,
                    currentChapter: mustPlayChapterRel.node,
                },
            });
        }

        console.debug(0, 'StoreService : onChapterStateChange : states', cloneDeep(this.states));
    }


    public async onLocalizationStateChange(_player?: PlayerNode) {
        const player = _player || this.states.player;
        const lang = player?.lang || 'fr';
        const trainings: TrainingLiteNode[] = player?.relTrainedForTrainingConnection?.edges.map(rel => rel.node) || [];

        console.debug(1, 'StoreService : onLocalizationStateChange', player?.email, lang, trainings);

        const localizationObject = await LocalizationService.getLocalizationsFromStorage(lang, trainings);

        // Flatten localizations
        function flattenObj(obj: any, parent?: string, res: any = {}) {
            const keys = Object.keys(obj);
            for (const key of keys) {
                const propName = parent ? parent + '.' + key : key;
                if (isObject(obj[key])) flattenObj(obj[key], propName, res);
                else res[propName] = obj[key];
            }
            return res;
        }

        const flattenLocalization = flattenObj(cloneDeep(localizationObject));

        const localizationsArray = [];
        for (const [key, value] of Object.entries(flattenLocalization)) {
            localizationsArray.push({
                __typename: 'Localization',
                _id: key,
                text: value,
            })
        }

        // @ts-ignore
        this.states.localizations = localizationsArray;

        // Write Localization in cache
        cache.writeData({
            data: {
                localizations: localizationsArray
            },
        });

        // Refresh Queries
        await this.$vue.$apollo.query({
            query: localizationQuery,
            fetchPolicy: 'cache-only',
        })
        // this.$vue.$root.$emit('onLocalizationStateChange');

        console.debug(0, 'StoreService : onLocalizationStateChange : states', cloneDeep(this.states));
    };


    public onExerciseStateChange(relMustTrainListResult: ApolloQueryResult<any>) {
        console.debug(1, 'StoreService : onExerciseStateChange', relMustTrainListResult);

        const relMustTrainList = relMustTrainListResult.data?.players[0]?.relMustTrainExerciseConnection?.edges;

        // Get an exercise to train
        const randomRelMustTrain: MustTrainRelation | undefined = relMustTrainList?.find((_rel: MustTrainRelation) => !_rel.done);
        const randomExerciseToTrain = randomRelMustTrain?.node;

        let trainingNotions = [];
        if (this.states.training?.relMustPlayChaptersConnection?.edges) {
            for (const relMustPlay of this.states.training.relMustPlayChaptersConnection.edges) {
                for (const relTeaches of relMustPlay.node.relTeachesNotionsConnection.edges) {
                    trainingNotions.push(relTeaches.node)
                }
            }
        }
        trainingNotions = uniqBy(trainingNotions, '_id');

        let notion: NotionNode | undefined;
        let exercise: ExerciseNode | undefined;

        // Returns if no relation MUST_TRAIN left or if current player training's chapters don't have TEACHES relations with notions
        if (randomExerciseToTrain && trainingNotions.length) {
            // Loop over each training notion exercises for find the exercise with _id of randomExerciseToTrain
            for (const trainingNotion of trainingNotions) {
                for (const relTests of trainingNotion.relTestsExercisesConnection.edges) {
                    const notionExercise = relTests.node;

                    // Save exercise and his parent notion
                    if (notionExercise._id === randomExerciseToTrain._id) {
                        notion = trainingNotion;
                        exercise = notionExercise;
                    }
                }
            }
        }

        this.states.notion = notion;
        this.states.exercise = exercise;

        cache.writeData({
            data: {
                currentNotion: notion || null,
                currentExercise: exercise || null
            },
        });

        console.debug(0, 'StoreService : onExerciseStateChange', cloneDeep(this.states));
    };


    // public  onPlayerTrainingNotionsStateChange(result: ApolloQueryResult<any>) {
    //     console.debug(1, 'StoreService : onPlayerTrainingNotionsStateChange', result);
    //
    //     const playerTrainingNotions : NotionNode[] = [];
    //     result.data?.players[0]?.relTrainedForTrainingConnection.edges.forEach((relTrainedForTrainingItem: RelTrainedForModel) => {
    //         relTrainedForTrainingItem.node.relTeachesNotionsConnection.edges.forEach((relTeachesNotionsItem: RelTeachesModel) => {
    //             playerTrainingNotions.push(relTeachesNotionsItem.node);
    //         })
    //     });
    //
    //     this.states.playerTrainingNotions = playerTrainingNotions;
    //
    //     cache.writeData({
    //         data: {
    //             playerTrainingNotions,
    //         },
    //     });
    //
    //     console.debug(0, 'StoreService : onPlayerTrainingNotionsStateChange', cloneDeep(this.states));
    // };
}

export default new StoreService();

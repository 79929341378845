import {NotionNode} from '@/models/notion.node';
import {DbObject} from '@/types/neo4j';

export interface TeachesDbRelation extends DbObject {
}

export interface TeachesRelation extends DbObject, TeachesDbRelation {
    node: NotionNode;
}

export const teachesFragment = `
    _id
    _ownerId
    _createdAt
    _updatedAt
`;

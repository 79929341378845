
























































































































































import {playerFetchQuery, PlayerNode, playerUpdateQuery} from '@/models/player.node';
import {TrainedForRelation} from '@/models/trainedFor.relation';
import {Localization, localizationQuery, LocalizationService} from '@/services/localization.service';
import UserService, {FirebaseUserModel, userFetchQuery} from '@/services/user.service';
import {merge} from 'lodash';
import {Component, Vue} from 'vue-property-decorator';


export interface NavigationEvent {
  view?: string;
  title?: string;
  showNavBar?: boolean
  color?: 'primary' | 'secondary';
  showBtnDisconnect?: boolean
}


@Component({
  name: 'NavBar',
  components: {},
  apollo: {
    user: {
      query: userFetchQuery,
      fetchPolicy: 'cache-only',
      // update: data => {
      //   console.debug(0, 'userFetchQuery', data, data.user);
      //   return data?.user?.length ? data?.user[0] : undefined;
      // }
    },
    player: {
      query: playerFetchQuery,
      fetchPolicy: 'cache-and-network',
      // variables() {
      //   return {
      //     email: getAuth().currentUser?.email,
      //   }
      // },
      update: data => data?.players?.length ? data?.players[0] : undefined,
      skip() {
        return !this.localizations || this.$route.fullPath.startsWith('/authentication/')
      },
    },
    localizations: {
      query: localizationQuery,
      fetchPolicy: 'cache-only',
      result(ApolloQueryResult, key) {
        this.trainingsLocalizationsLoaded = LocalizationService.isTrainingLocalizationsLoaded()
      }
    },
  },
})
export default class NavBar extends Vue {
  // @Prop({required: true}) player !: PlayerNode;
  user: FirebaseUserModel | undefined;
  player: PlayerNode | undefined;
  localizations: Localization | undefined;
  trainingsLocalizationsLoaded = false;

  showNavBar = true;
  showBtnDisconnect = false;
  view = 'advancement';
  color: 'primary' | 'secondary' = 'primary';
  title = 'SOON';


  get invertedColor(): 'primary' | 'secondary' {
    return this.color === 'primary' ? 'secondary' : 'primary';
  }


  get numberOfManagedPlayers(): number {
    return this.player?.relManagesPlayersConnection?.edges.length || 0;
  }


  created() {
    this.trainingsLocalizationsLoaded = LocalizationService.isTrainingLocalizationsLoaded()
    // this.$root.$on('onLocalizationStateChange',
    //     () => this.trainingsLocalizationsLoaded = LocalizationService.isTrainingLocalizationsLoaded());
  }


  mounted() {
    this.$root.$on('navigationEvent', (_event: NavigationEvent) => {
      console.debug(0, 'navigationEvent', _event);

      if (_event.showNavBar !== undefined) this.showNavBar = _event.showNavBar;
      if (_event.showBtnDisconnect !== undefined) this.showBtnDisconnect = _event.showBtnDisconnect;
      if (_event.color !== undefined) this.color = _event.color;
      if (_event.title !== undefined) this.title = _event.title;

      if (_event.view !== undefined) {
        this.view = _event.view;
        this.showNavBar = true;
      }
    });
  }


  onChange(_event: Event) {
    console.debug(0, 'NavBar : onChange', _event);
    this.showNavBar = true;
  }


  onClickTrainingForRelation(_event: { trainedForRelation: TrainedForRelation }): Promise<any> {
    console.debug(0, 'onClickTrainingBtn', _event.trainedForRelation);
    if (!this.player) return Promise.reject('onClickTrainingBtn : player is undefined');

    // -- savePlayerProgress
    const playerUpdateInput = {
      currentTrainingId: _event.trainedForRelation.node._id
    }

    // optimisticResponse : no 'await' and start xhr request for sync in same time
    this.$apollo.mutate({
      mutation: playerUpdateQuery,
      variables: {
        playerId: this.player._id,
        playerUpdateInput
      },
      optimisticResponse: {
        updatePlayers: {
          __typename: 'UpdatePlayersMutationResponse',
          players: [
            merge(this.player, playerUpdateInput)
          ],
        }
      },
    })

    return this.$navigate({name: 'Advancement'});
  }


  disconnect = () => UserService.signOut();
}

import {ApolloLink, GraphQLRequest} from 'apollo-link';
import {setContext} from 'apollo-link-context';
import {getAuth} from 'firebase/auth';


export const authHeaderLink: ApolloLink = setContext(async (operation: GraphQLRequest, {headers}: any) => {
    const auth = getAuth();
    const idToken = await auth?.currentUser?.getIdToken();
    return {
        headers: {
            ...headers,
            authorization: idToken ? `Bearer ${idToken}` : '',
        },
    };
});







































































































import {ExerciseNode, TextQuizAnswer} from '@/models/exercise.node';
import {LocalizationService} from '@/services/localization.service';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Route} from 'vue-router/types/router';


@Component({
  name: 'SessionQuiz',
  components: {},
})
export default class SessionQuiz extends Vue {
  @Prop({required: true}) exercise !: ExerciseNode;

  showErrorAdvice = false;
  showCongratulationAdvice = false;
  clickedAnswerId = '';
  transition = true;

  get correctAnswer(): TextQuizAnswer | undefined {
    if (!this.exercise) return;

    return this.exercise.content.answers.find(answer => answer.isCorrect);
  }


  mounted() {
    console.debug(0, 'Exercise : ' + this.exercise._id);
    this.toggleTransition();
  }


  toggleTransition(): void {
    this.transition = true;
    setTimeout(() => this.transition = false, 1000);
  }


  async onClickAnswer(_answer: TextQuizAnswer): Promise<void> {
    // if (this.showCongratulationAdvice || this.showErrorAdvice) return; // remove cheaters
    // await this.toggleAdvice();

    this.clickedAnswerId = _answer._id;

    if (_answer.isCorrect) this.showCongratulationAdvice = true;
    else this.showErrorAdvice = true;
  }


  async onCompleteExercise(_success: boolean): Promise<void | Route> {
    // this.expandAdvice = false;


    this.showErrorAdvice = false;
    this.showCongratulationAdvice = false;
    this.clickedAnswerId = '';

    this.$emit('onCompleteExercise', {success: _success});

    // await this.toggleAdvice();
  }


  getRandomCongratulation(): string {
    return LocalizationService.getRandomText('Session.Congratulations');
  }


  getBtnColor(_answer: TextQuizAnswer): string {
    if (_answer._id === this.clickedAnswerId) return _answer.isCorrect ? 'success' : 'error';
    else return 'secondary';
  }

}

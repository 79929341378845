import {FirebaseApp} from '@firebase/app';

import {getApp, initializeApp} from 'firebase/app';
import {connectAuthEmulator, getAuth} from 'firebase/auth';
import {connectFirestoreEmulator, getFirestore} from 'firebase/firestore';
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';
import {connectStorageEmulator, getStorage} from 'firebase/storage';


let firebaseService: FirebaseApp;

export function initializeFirebase() {
    if (!firebaseService?.name) {
        // Initialize Firebase
        firebaseService = initializeApp({
            projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
            appId: process.env.VUE_APP_FIREBASE_APP_ID,
            apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
            messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
            measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
            authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
            storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        });

        // const analytics = getAnalytics(app);
        const auth = getAuth();
        const firestore = getFirestore();
        const storage = getStorage();
        const functions = getFunctions(getApp());

        if (process.env.NODE_ENV === 'development') {
            console.debug(1, 'Firebase in development mode');

            const emulators = require('/firebase.json').emulators;

            const host = process.env.FIREBASE_HOST || 'localhost';

            connectAuthEmulator(auth, `http://${host}:` + emulators.auth.port);
            connectFirestoreEmulator(firestore, host, emulators.firestore.port);
            connectStorageEmulator(storage, host, emulators.storage.port);
            connectFunctionsEmulator(functions, host, emulators.functions.port);
        }
    }

}



























import ErrorToast from '@/components/ErrorToast.component.vue';
import NavBar from '@/components/NavBar.component.vue';
import AudioService from '@/services/audio.service';
import {Localization} from '@/services/localization.service';
import StoreService from '@/services/store.service';
import TimeTrackingService from '@/services/timeTracking.service';
import UserService from '@/services/user.service';
import {Component, Vue} from 'vue-property-decorator';


@Component({
  name: 'App',
  components: {
    ErrorToast,
    NavBar,
  },
})
export default class App extends Vue {
  localizations: Localization | undefined;

  initialized = false;
  showLoader = false;


  async created() {
    // Init UserService
    UserService.initialize();

    // Init Localizations
    await StoreService.initialize();

    this.initialized = true;
  }


  mounted() {
    // Start time tracking
    TimeTrackingService.initialize();

    // Start time tracking
    TimeTrackingService.startAppTimeTracking();

    // Set default audio params
    AudioService.mute();
    AudioService.setVolume(0.2, 0.3);

    // Change orientation when fullscreen change
    // document.addEventListener('fullscreenchange', (event) =>
    //     !!document.fullscreenElement && screen.orientation.lock('landscape').catch(err => console.warn('Error : landscape mode not supported', err))
    // );

    // Listen for root events that show and hide the loader
    let appLoaderTimeout: NodeJS.Timeout;

    this.$root.$on('displayAppLoader', (_event: { value: boolean }) => {
          this.showLoader = _event.value;

          // Define max loader timeout
          if (appLoaderTimeout) clearTimeout(appLoaderTimeout);
          appLoaderTimeout = setTimeout(() => this.showLoader = false, 30000);
        }
    );
  }

  destroyed() {
    TimeTrackingService.endAppTimeTracking();
  }
}

import {authHeaderLink} from '@/services/apollo-auth-header-link.service';
import {variableInjectionLink} from '@/services/apollo-variable-injection-link.service';
import {InMemoryCache} from 'apollo-cache-inmemory'
import {ApolloClient} from 'apollo-client'
import {ApolloLink, Operation} from 'apollo-link';
import {createHttpLink} from 'apollo-link-http'
import gql from 'graphql-tag';
import {merge, upperFirst} from 'lodash';
import VueApollo from 'vue-apollo';

// Cache implementation
export const cache = new InMemoryCache();

// HTTP connection to the API
const httpLink = createHttpLink({
    uri: process.env.VUE_APP_APOLLO_ENDPOINT,
    // uri: 'http://localhost:8081/graphql',
})

const localTypeDefs = gql`
    directive @client on FIELD
    
    extend type Exercise {
        content: TextQuiz @computed(from: ["_id", "type", "contentJson"])
    }
    
    type TextQuizAnswer {
        __typename: String!
        _id: ID!
        textId: String!
        isCorrect: Boolean!
    }
    
    type TextQuiz {
        __typename: String!
        _id: ID!
        imageUrl: String
        questionId: String!
        explanationId: String!
        answers: [TextQuizAnswer!]!
    } 
    
    type Test {
        _id: ID!
    }

    type Localization {
        _id: ID!
        text: String!
    }

    type Item {
        id: ID!
        text: String!
        done: Boolean!
    }
    
    #    type Mutation {
    #        checkItem(id: ID!): Boolean
    #        addItem(text: String!): Item
    #    }
`;

// const checkItemMutation = gql`
//     mutation($id: ID!) {
//         checkItem(id: $id) @client
//     }
// `;

const localResolvers = {
    Exercise: {
        content(source: { _id: string, type: string, contentJson: string }) {
            const typename = upperFirst(source.type);
            const object = merge(
                {
                    __typename: typename,
                    _id: source._id + '_' + typename,
                    imageUrl: '',
                    questionId: '',
                    explanationId: '',
                    answers: [],
                },
                JSON.parse(source.contentJson)
            );
            object.answers.map((answer: any, index: number) => merge(answer, {__typename: 'TextQuizAnswer', _id: object._id + '_TextQuizAnswer_' + index}));
            // console.log(object);
            return object;
        },
    },
    // Query: {
    //     exercise(root: any, args: any, context: any) {
    //         return null
    //     },
    // },
    //     Mutation: {
    //         checkItem: (_, {id}, {cache}) => {
    //             const data = cache.readQuery({query: todoItemsQuery});
    //             const currentItem = data.todoItems.find(item => item.id === id);
    //             currentItem.done = !currentItem.done;
    //             cache.writeQuery({query: todoItemsQuery, data});
    //             return currentItem.done;
    //         },
    //     }
}


// --- Required Variable Link
// @see : https://www.apollographql.com/blog/apollo-client/using-apollo-link-to-handle-dependent-queries/

interface RequiresVariable {
    variableName: string
    operation: Operation
}


// Create the apollo client
const apolloClient = new ApolloClient({
    link: ApolloLink.from([authHeaderLink, variableInjectionLink, httpLink]),
    cache,
    typeDefs: localTypeDefs,
    resolvers: localResolvers,
})


export const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
})


























import {LocalizationService} from '@/services/localization.service';
import {Component, Vue} from 'vue-property-decorator';


@Component({
  name: 'SessionEncouragement',
  components: {},
})
export default class SessionEncouragement extends Vue {
  startTransitionLeft = false;

  mounted() {
    setTimeout(() => this.startTransitionLeft = true, 500);
  }

  getRandomEncouragement(): string {
    return LocalizationService.getRandomText('Session.Encouragements');
  }
}

import {TrainingLiteNode} from '@/models/training.node';
import {downloadJsonFile, uploadJsonFile} from '@/services/storage.service';
import StoreService from '@/services/store.service';
import {VueService} from '@/services/vue.service';
import gql from 'graphql-tag';
import {isNull, isObject, kebabCase, merge, omitBy, random} from 'lodash';

export interface Localization {
    __typename: 'Localization',
    _id: string,
    text: string,
}

export const localizationQuery = gql`
    query {
        localizations @client {
            __typename
            _id
            text
        }
    }
`;


export class LocalizationService extends VueService {
    constructor() {
        super();

        console.debug(1, 'LocalizationService : constructor');        // Initialize Firebase
    }


    static async getLocalizationsFromStorage(_lang: string, _trainings: TrainingLiteNode[]): Promise<any> {
        console.debug(0, 'LocalizationService : getLocalizations', _lang, _trainings);

        // Default localization
        let localizationObject = await downloadJsonFile(`/localizations/app.fr.localization.json`);

        // Get Trainings localizations
        if (_trainings.length) {
            for (const training of _trainings) {
                const trainingLocalization = await downloadJsonFile(`/localizations/${kebabCase(training.name)}.training.${_lang}.localization.json`);
                localizationObject = merge(localizationObject, trainingLocalization);
            }
        }

        return localizationObject;
    }


    static async uploadLocalizationsToStorage(_trainingName: string, _localizationsObject: any): Promise<void> {
        const trainingLocalization = await downloadJsonFile(`/localizations/${kebabCase(_trainingName)}.training.${StoreService.states.player?.lang}.localization.json`);
        const localizations = merge(trainingLocalization, _localizationsObject);

        for (const [key, value] of Object.entries(localizations)) {
            if (isObject(value)) localizations[key] = omitBy(value, isNull);
        }

        console.debug(0, 'LocalizationService : uploadLocalizationsToStorage', _trainingName, _localizationsObject, localizations);

        await uploadJsonFile(
            `localizations/${kebabCase(_trainingName)}.training.fr.localization.json`,
            localizations
        );
    }


    // getValue(key: string) {
    //     try {
    //         const value = at(StoreService.states.localization, key)[0];
    //         // console.log('localizationService : getValue', value, key, StoreService.states.localization)
    //
    //         return isString(value) && StoreService.states.player ? value.replace('PlayerName', StoreService.states.player.pseudo) : value;
    //     } catch (e) {
    //         console.error(e);
    //         console.error(`No value found for ${key} in module localization ${StoreService.states.player?.currenttrainingName}`);
    //         return 'error';
    //     }
    // }


    static getLocale(key: string, _localizations?: Localization[]) {
        _localizations = _localizations || StoreService.states.localizations;

        const localization = _localizations.find((loc) => loc._id === key);
        const locale = localization?.text;

        // Force Refresh
        // if (!locale) {
        //     console.debug(2, 'LocalizationService : getLocale : force store refresh');
        //     StoreService.onLocalizationStateChange();
        //     _localizations = StoreService.states.localizations;
        //
        //     localization = _localizations.find((loc) => loc._id === key);
        //     locale = localization?.text;
        // }

        if (locale) {
            return StoreService.states.player ? locale.replace('PlayerName', StoreService.states.player.pseudo) : locale;
        }
        else {
            console.debug(2, `LocalizationService : getLocale : No value found for localization : ${key}`);
            return '';
        }
    }


    static getRandomText(_startKey: string): string {
        const localizations = StoreService.states.localizations.filter(locale => locale._id.startsWith(_startKey));
        if (!localizations) return '';

        const randomLocalization: Localization = localizations[random(0, localizations.length - 1)];

        return LocalizationService.getLocale(randomLocalization._id);
    }


    static isTrainingLocalizationsLoaded(): boolean {
        return StoreService.states.localizations.some(locale => locale._id.startsWith('Trainings.'));
    }
}

// export default new LocalizationService();

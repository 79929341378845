


































































































import userService from '@/services/user.service';
import {isString, startCase} from 'lodash';
import {Component, Vue} from 'vue-property-decorator';


@Component({
  name: 'Login',
  components: {}
})
export default class Login extends Vue {
  form = {
    email: '',
    password: ''
  };

  valid = false;
  emailRules = [
    (value: string) => !!value || 'E-mail is required',
    (value: string) => /.+@.+\..+/.test(value) || 'E-mail must be valid',
  ]
  errorMsg = '';

  passwordByPassOrganisations = ['pep11'];

  get organisationTitle(): string {
    return isString(this.$route.query?.organisation) ? startCase(this.$route.query.organisation) : '';
  }


  get hasPasswordByPass(): boolean {
    return isString(this.$route.query?.organisation) && this.passwordByPassOrganisations.includes(this.$route.query?.organisation);
  }


  get byPassedPassword(): string {
    if (this.form.email) {
      // List all this.passwordByPassOrganisations
      switch (this.$route.query.organisation) {
        case 'pep11':
          // http://localhost:8080/?organisation=pep11
          const match = this.form.email.match(/(.+)@/);
          return match?.length ? match[1] : '';
        default:
          return '';
      }
    }
    else return '';
  }


  mounted() {
    this.$root.$emit('navigationEvent', {showNavBar: false});
  }


  submit() {
    this.$root.$emit('displayAppLoader', {value: true});

    const password = this.hasPasswordByPass ? this.byPassedPassword : this.form.password;

    userService.authenticated(this.form.email, password)
        .then(result => {
          this.$root.$emit('displayAppLoader', {value: false});
        })
        .catch(err => {
          this.$root.$emit('displayAppLoader', {value: false});

          if (err?.code) this.errorMsg = userService.getErrorMessage(err.code, err.message)
          else {
            this.errorMsg = 'Erreur : nous n\'avons pas pu vous connecter';
            console.error(err);
          }
        });
  }
}

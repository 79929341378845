




























































import {toastEventOptions} from '@/components/ErrorToast.component.vue';
import userService from '@/services/user.service';
import {Component, Vue} from 'vue-property-decorator';


@Component({
  name: 'Password',
  components: {}
})
export default class Password extends Vue {
  form = {
    show: true,
    email: '',
  };

  valid = false;
  emailRules = [
    (value: string) => !!value || 'E-mail is required',
    (value: string) => /.+@.+\..+/.test(value) || 'E-mail must be valid',
  ]
  errorMsg = '';


  mounted() {
    this.$root.$emit('navigationEvent', {showNavBar: false});
  }


  submit() {
    userService.resetPassword(this.form.email)
        .then(result => {
          const options: toastEventOptions = {
            closeTimeout: 3000,
            type: 'success',
            text: 'Email envoyé !' // TODO : i18n
          }
          this.$root.$emit('toastEvent', options);
          this.form.show = false;
        })
        .catch((err) => {
              if (err?.code) this.errorMsg = userService.getErrorMessage(err.code, err.message)
              else {
                this.errorMsg = 'Erreur : nous n\'avons pas pu vous connecter';
                console.warn(err);
              }
            }
        )

  }
}

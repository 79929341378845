import {trainingFragment, TrainingLiteNode, TrainingNotionsNode} from '@/models/training.node';
import {DbObject} from '@/types/neo4j';
import gql from 'graphql-tag';

export interface TrainedForDbRelation extends DbObject {
    startedAt: Date
    finishedAt: Date
}

export interface TrainedForRelation extends DbObject, TrainedForDbRelation {
    node: TrainingLiteNode
}

export interface TrainedForNotionsRelation extends DbObject, TrainedForDbRelation {
    node: TrainingNotionsNode
}

export const trainedForFragment = `
    _id
    _ownerId
    _createdAt
    _updatedAt
    
    startedAt
    finishedAt
`;


export const trainedForUpdateOrCreateByIdsQuery = gql`
    mutation trainedForUpdateOrCreateByIdsQuery (
        $playerId: ID!,
        $trainingId: ID!,
        $relCreateInput: RelTrainedForCreateInput!
    ) {
        updatePlayers(
            where: { _id: $playerId },
            connect: {
                relTrainedForTraining: [
                    {
                        where: {
                            node: {
                                _id: $trainingId
                            }
                        },
                        edge: $relCreateInput
                    }
                ]
            }) {
            players {
                _id

                relTrainedForTrainingConnection {
                    edges {
                        ${trainedForFragment}

                        node {
                            ${trainingFragment}
                        }
                    }
                }
            }
        }
    }
`;

import {ExerciseNode} from '@/models/exercise.node';
import {DbObject} from '@/types/neo4j';

export interface MustAchieveDbRelation extends DbObject {
}

export interface MustAchieveRelation extends DbObject, MustAchieveDbRelation {
    node: ExerciseNode;
}

export const mustAchieveFragment = `
    _id
    _ownerId
    _createdAt
    _updatedAt
`;

import {ManagedPlayerLiteNode, ManagedPlayerNode} from '@/models/managedPlayer.node';
import {DbObject} from '@/types/neo4j';


export interface ManagesDbRelation extends DbObject {
    xp: number
}


export interface ManagesLiteRelation extends ManagesDbRelation {
    node: ManagedPlayerLiteNode;
}


export interface ManagesRelation extends ManagesDbRelation {
    node: ManagedPlayerNode;
}


export const managesFragment = `
    _id
    _ownerId
    _createdAt
    _updatedAt
`;


// export const managesUpdateOrCreateByIdsQuery = gql`
//     mutation managesUpdateOrCreateByIdsQuery (
//         $playerId: ID!,
//         $managedPlayerId: ID!,
//         $relCreateInput: RelManagesCreateInput!
//     ) {
//         updatePlayers(
//             where: { _id: $playerId },
//             connect: {
//                 relManagesPlayers: [
//                     {
//                         where: {
//                             node: {
//                                 _id: $managedPlayerId
//                             }
//                         },
//                         edge: $relCreateInput
//                     }
//                 ]
//             }) {
//             players {
//                 _id
//
//                 relManagesPlayersConnection {
//                     edges {
//                         ${managesFragment}
//
//                         node {
//                             _id
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `;



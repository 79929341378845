


























































































import mixins from '@/mixins';
import {exerciseCreateFromNotionQuery, exerciseDeleteQuery, ExerciseNode} from '@/models/exercise.node';
import {NotionLiteNode} from '@/models/notion.node';
import {TrainingLiteNode} from '@/models/training.node';
import {LocalizationService} from '@/services/localization.service';
import StoreService from '@/services/store.service';
import {ExerciseForm} from '@/types/questions.vue';
import {RefreshTreeviewOptions} from '@/views/questions/Questions.vue';
import {cloneDeep} from 'lodash';
import {Component, Vue} from 'vue-property-decorator';


@Component({
  mixins: [mixins],
  name: 'QuestionExercise',
  components: {},
})
export default class QuestionExercise extends Vue {
  training: TrainingLiteNode | null = null;
  notion: NotionLiteNode | null = null;
  exercise: ExerciseNode | null = null;

  exerciseFormDialog = false;
  isDialogLoading = false;

  form: ExerciseForm = {
    valid: true,
    lazyValidation: true,
    question: '',
    questionId: '',
    answer1: '',
    answer1Id: '',
    answer2: '',
    answer2Id: '',
    answer3: '',
    answer3Id: '',
    explanation: '',
    explanationId: '',
  }


  reset() {
    this.form = {
      // itemId: '',
      // action: '',
      valid: true,
      lazyValidation: true,
      question: '',
      questionId: '',
      answer1: '',
      answer1Id: '',
      answer2: '',
      answer2Id: '',
      answer3: '',
      answer3Id: '',
      explanation: '',
      explanationId: '',
    };

    this.training = null;
    this.notion = null;
    this.exercise = null;

    const form = this.$refs.exerciseFormRef as HTMLFormElement;
    if (form) form.reset();
  }


  closeEditExerciseForm() {
    this.exerciseFormDialog = false;
    this.isDialogLoading = false;
    this.reset();
  }


  /**
   * @see Questions.vue for usage
   * */
  openEditExerciseForm(
      _training: TrainingLiteNode,
      _notion: NotionLiteNode,
      _exercise: ExerciseNode
  ) {
    console.debug(0, 'QuestionsExercise.vue : openEditExerciseForm', _exercise);
    this.exerciseFormDialog = false;

    // Update exercise
    if (_exercise) {
      this.form = {
        valid: true,
        lazyValidation: true,
        question: this.$loc('Exercises.' + _exercise.content.questionId),
        questionId: _exercise.content.questionId,
        answer1: this.$loc('Exercises.' + _exercise.content.answers[0].textId),
        answer1Id: _exercise.content.answers[0].textId,
        // @ts-ignores
        answer2: this.$loc('Exercises.' + _exercise.content.answers[1].textId),
        // @ts-ignores
        answer2Id: _exercise.content.answers[1].textId,
        answer3: '',
        answer3Id: '',
        explanation: this.$loc('Exercises.' + _exercise.content.explanationId) || '',
        explanationId: _exercise.content.explanationId,
      }

      if (_exercise.content.answers.length > 2) {
        // @ts-ignores
        this.form.answer3 = this.$loc('Exercises.' + _exercise.content.answers[2].textId);
        // @ts-ignores
        this.form.answer3Id = _exercise.content.answers[2].textId;
      }
    }

    this.training = cloneDeep(_training);
    this.notion = cloneDeep(_notion);
    this.exercise = cloneDeep(_exercise);

    this.exerciseFormDialog = true;
  }


  /**
   * @see Questions.vue for usage
   * */
  openCreateExerciseForm(
      _training: TrainingLiteNode,
      _notion: NotionLiteNode
  ) {
    if (!_training || !_notion) return console.warn('Questions.vue : openCreateExerciseForm : params are undefined');
    const preName = _training.name + '_Exercise_' + (new Date()).getTime() + '_';

    // Update exercise
    this.form = {
      // action: 'createExercise',
      valid: false,
      lazyValidation: false,
      question: '',
      questionId: preName + 'Question',
      answer1: '',
      answer1Id: preName + 'Answer_1',
      answer2: '',
      answer2Id: preName + 'Answer_2',
      answer3: '',
      answer3Id: preName + 'Answer_3',
      explanation: '',
      explanationId: preName + 'Explanation',
    };

    this.training = cloneDeep(_training);
    this.notion = cloneDeep(_notion);
    this.exercise = null;

    this.exerciseFormDialog = true;
  }


  /**
   * @see Questions.vue for usage
   * */
  async deleteExercise(
      _training: TrainingLiteNode,
      _notion: NotionLiteNode,
      _exercise: ExerciseNode
  ): Promise<void> {
    console.debug(0, 'QuestionsExercise.vue : onSubmitDeleteForm', _exercise);

    // let exercise: ExerciseNode | undefined = this.getExerciseByItemExerciseId(_deletedItem);
    this.training = cloneDeep(_training);
    this.notion = cloneDeep(_notion);
    this.exercise = cloneDeep(_exercise);

    if (_exercise) {
      // -- Delete exercise in database
      const result = await this.$apollo.mutate({
        mutation: exerciseDeleteQuery,
        variables: {
          exerciseId: _exercise._id,
        },
      });
      if (!result?.data?.deleteExercises.nodesDeleted) return console.warn('QuestionsExercise.vue : deleteExercise : exercise _exercise._id was not deleted');

      // -- Update training localizations
      const localizations: any = {
        Exercises: {}
      };

      localizations.Exercises[_exercise.content.questionId] = null;
      localizations.Exercises[_exercise.content.answers[0].textId] = null;
      // @ts-ignores
      localizations.Exercises[_exercise.content.answers[1].textId] = null;
      // @ts-ignores
      if (_exercise.content.answers[2]) localizations.Exercises[_exercise.content.answers[2].textId] = null;
      localizations.Exercises[_exercise.content.explanationId] = null;

      await this.saveExerciseLocalizations(_training.name, localizations); // No await because delete
    }
  }


  async submitEditExerciseForm(): Promise<void> {
    console.debug(0, 'QuestionsExercise.vue : submitForm', this.form);

    const form = this.$refs.exerciseFormRef as HTMLFormElement;
    if (form) form.validate();
    if (!this.form.valid) return console.debug(0, 'QuestionsExercise.vue : submitForm invalid');

    await this.saveExercise();

    this.closeEditExerciseForm();
  }


  async saveExercise(): Promise<void> {
    this.isDialogLoading = true;

    try {
      // --- Update training localizations

      const localizations: any = {
        Exercises: {}
      };

      localizations.Exercises[this.form.questionId] = this.form.question;
      localizations.Exercises[this.form.answer1Id] = this.form.answer1;
      localizations.Exercises[this.form.answer2Id] = this.form.answer2;
      localizations.Exercises[this.form.answer3Id] = this.form.answer3;
      localizations.Exercises[this.form.explanationId] = this.form.explanation;

      if (!this.training) return console.warn('Questions.vue : createExercise : training is undefined');
      await this.saveExerciseLocalizations(this.training.name, localizations);

      // -- Update exercise in database

      if (this.exercise) {
        // TODO : Update exercise in database
        // await this.$apollo.mutate({
        //   mutation: exerciseUpdateQuery,
        //   variables: {
        //     exerciseId: this.exercise._id,
        //   },
        // });
      }

      // -- Create exercise

      else {
        // --- Save in database
        const names = this.form.questionId.match(/.+_Exercise_\d+/);
        if (!names) return console.error('Questions.vue : saveExercise : exercise.name is undefined');
        const name = names[0];

        const content = {
          imageUrl: '',
          questionId: this.form.questionId,
          explanationId: this.form.explanationId,
          answers: [
            {
              textId: this.form.answer1Id,
              isCorrect: true   // TODO
            },
            {
              textId: this.form.answer2Id,
              isCorrect: true   // TODO
            },
            {
              textId: this.form.answer3Id,
              isCorrect: true   // TODO
            },
          ]
        };

        const exercise = {
          name,
          xp: 0,                   // TODO
          difficulty: 0,            // TODO
          type: 'textQuiz',
          contentJson: JSON.stringify(content),
        };

        await this.$apollo.mutate({
          mutation: exerciseCreateFromNotionQuery,
          variables: {
            notionId: this.notion?._id,
            exerciseCreateInput: exercise
          },
        });

        // --- Refresh tree View

        const event: RefreshTreeviewOptions = {
          onlyFetchNotionId: this.notion?._id
        }
        this.$emit('onRefreshTreeViewEvent', event);
      }

      return;
    } catch (e) {
      this.closeEditExerciseForm();
      console.error('Questions.vue : saveExercise', e);
    }

  }


  async saveExerciseLocalizations(_trainingName: string, _newLocalizationsObject: any): Promise<void> {
    console.debug(0, 'QuestionsExercise.vue : saveExerciseLocalizations', _newLocalizationsObject);

    // --- Upload localizations
    if (!this.training) return console.warn('Questions.vue : saveExerciseLocalizations : training is undefined');
    await LocalizationService.uploadLocalizationsToStorage(_trainingName, _newLocalizationsObject);
    await StoreService.onLocalizationStateChange();
  }
}

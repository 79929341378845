var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.player && _vm.localizations)?_c('v-row',{staticClass:"pb-16 ma-0 secondary primary--text",attrs:{"id":"StatsView","justify":"center"}},[_c('v-col',{staticClass:"d-flex flex-column flex-grow-1 justify-start pt-md-16",attrs:{"cols":"12","md":"10","lg":"8"}},[[_c('v-treeview',{ref:"treeviewRef",attrs:{"items":_vm.items,"load-children":_vm.fetchNotionItem,"rounded":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(false && item && open)?_c('div'):_vm._e(),_vm._v(" "),(item.type === 'training')?_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-school-outline")]):_vm._e(),(item.type === 'notion')?_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-book-open-page-variant-outline")]):_vm._e(),(item.type === 'answer')?_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-message-outline")]):_vm._e(),(item.type === 'explanation')?_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-lightbulb-on-outline")]):_vm._e()]}},{key:"label",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(false && item && open)?_c('div'):_vm._e(),_vm._v(" "),(_vm.showItemName)?_c('div',[(item.type === 'editExerciseButtons')?_c('div',[_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.questionExerciseComponent.openEditExerciseForm(item.training, item.notion, item.exercise);}}},[_vm._v(" "+_vm._s(_vm.$loc('Questions.edit'))+" ")]),_c('v-btn',{staticClass:"ml-8",attrs:{"color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.openDeleteDialog(item);}}},[_vm._v(" "+_vm._s(_vm.$loc('Questions.delete'))+" ")])],1):(item.type === 'createExerciseButton')?_c('div',[_c('v-btn',{staticClass:"ml-0",attrs:{"color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.questionExerciseComponent.openCreateExerciseForm(item.training, item.notion);}}},[_vm._v(" "+_vm._s(_vm.$loc('Questions.create_exercise'))+" ")])],1):_c('span',{staticClass:"text-pre-wrap",class:{'font-italic' : item.type === 'explanation'},staticStyle:{"max-width":"80%"}},[_c('span',[_vm._v(_vm._s(_vm.$loc(item.textId) || '...'))])])]):_vm._e()]}},{key:"append",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(false && item && open)?_c('div'):_vm._e()]}}],null,false,465283041)})],_c('v-dialog',{attrs:{"width":"400px","max-width":"90%"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.$loc('Questions.form_delete_title'))+" ")]),_c('v-card-actions',{staticClass:"justify-space-around"},[_c('v-btn',{attrs:{"disabled":_vm.isDeleteDialogLoading,"color":"grey","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" "+_vm._s(_vm.$loc('Common.cancel'))+" ")]),_c('v-btn',{attrs:{"loading":_vm.isDeleteDialogLoading,"color":"error","text":""},on:{"click":function($event){return _vm.onSubmitDeleteDialog()}}},[_vm._v(" "+_vm._s(_vm.$loc('Common.confirm'))+" ")])],1)],1)],1),_c('QuestionExerciseForm',{ref:"questionExerciseComponent",attrs:{"player":_vm.player,"notions":_vm.notions},on:{"onRefreshTreeViewEvent":function($event){return _vm.refreshTreeView($event)}}})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
























import {EndedRelation} from '@/models/ended.relation';
import {MustPlayRelation, MustPlayRelationExtended} from '@/models/mustPlay.relation';
import {PlayerNode} from '@/models/player.node';
import {TrainingChaptersNode} from '@/models/training.node';
import {getChapterMustAchieveExercicesWhereNotTookRelations, isPlayerMasteringNotion} from '@/services/exercise.service';
import {SESSION_MAX_EXERCICES} from '@/variables';
import {cloneDeep, sortBy} from 'lodash';
import {Component, Prop, Vue} from 'vue-property-decorator';


@Component({
  name: 'AdvancementPath',
  components: {},
  apollo: {
    // localizations: {
    //   query: localizationQuery,
    //   fetchPolicy: 'cache-only',
    // },
  }
})
export default class AdvancementPath extends Vue {
  @Prop({required: true}) player !: PlayerNode;
  @Prop({required: true}) training !: TrainingChaptersNode;

  isPlayerMasteringNotion = isPlayerMasteringNotion;

  // localizations: Localization | undefined;

  // mustPlayRelations: MustPlayRelation[] = [];


  get mustPlayRelationsExtended(): MustPlayRelationExtended[] {
    let mustPlayRelations: MustPlayRelationExtended[] = [];

    if (this.player && this.training?.relMustPlayChaptersConnection?.edges) {
      mustPlayRelations = cloneDeep(this.training.relMustPlayChaptersConnection.edges);

      // Indicate chapters with exercises to achieved and not took yet
      mustPlayRelations.forEach(mustPlayRelation => mustPlayRelation.haveExerciseNotAchievedYet =
          getChapterMustAchieveExercicesWhereNotTookRelations(cloneDeep(this.player), mustPlayRelation.node).length);

      // Remove chapters with less than SESSION_MAX_EXERCICES exercises
      mustPlayRelations = mustPlayRelations.filter(rel => rel.node.relMustAchieveExercisesConnection.edges.length >= SESSION_MAX_EXERCICES);

      mustPlayRelations = sortBy(mustPlayRelations, 'order'); //.reverse();

      // ** Get Only one not completed chapter
      // // Get all player achieved chapters
      // const filteredChapters = mustPlayRelations.filter(rel => !rel.haveExerciseNotAchievedYet);
      // // Add the first not completed chapter
      // const notAchievedChapter = mustPlayRelations.find(rel => rel.haveExerciseNotAchievedYet);
      // if (notAchievedChapter) filteredChapters.push(notAchievedChapter);

      // ** Unlock chapter gradually (each x days)
      if (this.player.graduallyUnlockChapterDays) {
        const relTrainedFor = this.player.relTrainedForTrainingConnection.edges.find(relTrainedFor => relTrainedFor.node._id = this.training._id);

        if (relTrainedFor) {
          const differenceBetweenDates = (new Date()).getTime() - (new Date(relTrainedFor._createdAt)).getTime();
          const differenceBetweenDatesInDays = differenceBetweenDates / (1000 * 3600 * 24);
          const unlockedChaptersNumber = Math.ceil(differenceBetweenDatesInDays / this.player.graduallyUnlockChapterDays);
          mustPlayRelations = mustPlayRelations.slice(0, unlockedChaptersNumber);
          console.debug(0, 'AdvancementPath : mustPlayRelationsExtended', relTrainedFor._createdAt, differenceBetweenDatesInDays, this.player.graduallyUnlockChapterDays, unlockedChaptersNumber);
        }
      }
    }

    // console.debug(0, 'AdvancementPath : mustPlayRelations', cloneDeep(mustPlayRelations));
    return mustPlayRelations;
  }


  created() {
  }


  mounted() {
    console.debug(0, 'AdvancementPath : mounted', cloneDeep(this.training));

    setTimeout(() => this.scrollToFirstChapterToPlay(), 1000);
  }

  // scrollToElement() {
  //   const [el] = this.$refs.last;
  //   if (el) {
  //     el.scrollIntoView({ behavior: "smooth" });
  //   }
  // }


  getPlayerEndedRelation(_mustPlayRelation: MustPlayRelation): EndedRelation | undefined {
    if (this.player) {
      const endedRelations: EndedRelation[] = this.player.relEndedChaptersConnection.edges;
      return endedRelations.find(rel => rel.node._id === _mustPlayRelation.node._id);
    }
    return;
  }


  scrollToFirstChapterToPlay() {
    const firstMustPlayRelationToScroll = this.mustPlayRelationsExtended.find(rel => !!rel.haveExerciseNotAchievedYet);
    // console.debug('firstMustPlayRelationToScroll', firstMustPlayRelationToScroll);
    if (firstMustPlayRelationToScroll) this.$scrollToElement(firstMustPlayRelationToScroll._id);
  }
}

import {notionFragment} from '@/models/notion.node';
import {testsFragment} from '@/models/tests.relation';
import {DbObject} from '@/types/neo4j';
import gql from 'graphql-tag';


export interface ExerciseDbNode extends DbObject {
    name: string
    xp: number
    difficulty: number
    type: string
    contentJson: string
}

export interface TextQuizNode {
    __typename: string
    _id: string
    imageUrl: string
    questionId: string
    explanationId: string
    answers: [TextQuizAnswer]
}

export interface TextQuizAnswer {
    __typename: string
    _id: string
    textId: string
    isCorrect: boolean
}

export interface ExerciseNode extends DbObject, ExerciseDbNode {
    content: TextQuizNode
}


export const exerciseFragment = `
    _id
    _ownerId
    _createdAt
    _updatedAt

    name
    xp
    difficulty
    type
    contentJson
    
    content @client {
        __typename
        _id
        imageUrl
        questionId
        explanationId
        answers {
            __typename
            _id
            textId
            isCorrect
        }
    } 
`;

export const currentExerciseQuery = gql`
    query currentExerciseQuery {
        currentExercise @client {
            ${exerciseFragment}
        }
    }
`;


export const exerciseDeleteQuery = gql`
    mutation exerciseDeleteQuery(
        $exerciseId: ID!
    ) {
        deleteExercises(
            where: { _id: $exerciseId }
        ) {
            nodesDeleted
        }
    }
`;


export const exerciseCreateFromNotionQuery = gql`
    mutation notionUpdateQuery(
      $notionId: ID!
      $exerciseCreateInput: ExerciseCreateInput!
    ) {
      updateNotions(
        where: { _id: $notionId }
        create: { relTestsExercises: { edge: {}, node: $exerciseCreateInput } }
      ) {
        notions {
          _id
    
          relTestsExercisesConnection {
            edges {
              ${testsFragment}
    
              node {
                ${exerciseFragment}
              }
            }
          }
        }
      }
    }
`;


// export const exerciseCreateQuery = gql`
//     mutation exerciseCreateQuery(
//         $exerciseCreateInput: [ExerciseCreateInput!]!
//     ) {
//       createExercises(input: $exerciseCreateInput) {
//         exercises {
//           ${exerciseFragment}
//         }
//       }
//     }
// `;

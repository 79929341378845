import {ExerciseNode} from '@/models/exercise.node';
import {TrainedForDbRelation} from '@/models/trainedFor.relation';
import {TrainingLiteNode, TrainingNotionsNode} from '@/models/training.node';
import {DbObject} from '@/types/neo4j';

export interface TestsDbRelation extends DbObject {
}

export interface ConceivesRelation extends DbObject, TestsDbRelation {
    node: TrainingLiteNode
}

export interface ConceivesNotionsRelation extends DbObject, TrainedForDbRelation {
    node: TrainingNotionsNode
}

export const conceivesFragment = `
    _id
    _ownerId
    _createdAt
    _updatedAt
`;

import {chapterFragment, ChapterNode} from '@/models/chapter.node';
import {exerciseFragment} from '@/models/exercise.node';
import {InstructsLiteRelation, InstructsRelation} from '@/models/instructs.relation';
import {mustAchieveFragment} from '@/models/mustAchieve.relation';
import {mustPlayFragment, MustPlayRelation} from '@/models/mustPlay.relation';
import {notionFragment} from '@/models/notion.node';
import {teachesFragment} from '@/models/teaches.relation';
import {testsFragment} from '@/models/tests.relation';
import {DbObject} from '@/types/neo4j';
import gql from 'graphql-tag';


export interface TrainingDbNode extends DbObject {
    name: string
}

export interface TrainingChaptersNode extends DbObject, TrainingDbNode {
    relMustPlayChaptersConnection: {
        edges: MustPlayRelation[]
    }
}

export interface TrainingNotionsNode extends DbObject, TrainingDbNode {
    relInstructsNotionsConnection: {
        edges: InstructsLiteRelation[]
    }
}

export interface TrainingLiteNode extends DbObject, TrainingDbNode {
}

export const trainingFragment = `
    _id
    _ownerId
    _createdAt
    _updatedAt

    name
`;


// export const trainingFetchAllLiteQuery = gql`
//     query trainingFetchAllLiteQuery {
//         trainings {
//             ${trainingFragment}
//         }
//     }
// `;


export const trainingFetchQuery = gql`
    query trainingFetchQuery(
        $trainingId: ID!
    ) {
        trainings(where: { _id: $trainingId }, options: {
                sort: [{ name: ASC }]
            }) {
            ${trainingFragment}
            
            relMustPlayChaptersConnection {
                edges {
                    ${mustPlayFragment}

                    node {
                        ${chapterFragment}

                        relMustAchieveExercisesConnection {
                            edges {
                                ${mustAchieveFragment}

                                node {
                                    ${exerciseFragment}
                                }
                            }
                        }
                        
                        relTeachesNotionsConnection {
                            edges {
                                ${teachesFragment}

                                node {
                                    ${notionFragment}

                                    relTestsExercisesConnection {
                                        edges {
                                            ${testsFragment}

                                            node {
                                                ${exerciseFragment}
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;


// export const trainingsFetchQuery = gql`
//     query trainingFetchQuery {
//         trainings {
//             ${trainingFragment}
//
//             relMustPlayChaptersConnection {
//                 edges {
//                     ${mustPlayFragment}
//
//                     node {
//                         ${chapterFragment}
//                     }
//                 }
//             }
//         }
//     }
// `;


export function getChapterById(_training: TrainingChaptersNode, _chapterId: string): ChapterNode | undefined {
    const mustPlayChapterRelations = _training.relMustPlayChaptersConnection.edges;
    const mustPlayChapterRel = mustPlayChapterRelations?.find(_rel => _rel.node._id === _chapterId);

    return mustPlayChapterRel?.node;
}

import {Howl, Howler, HowlOptions} from 'howler';

export class Audio extends Howl {
    name = '';

    constructor(options: HowlOptions) {
        super(options);
    }
}

export interface AudioOptions {
    id: string
    url?: any
    filename?: string
    params?: {
        isMusic?: boolean
    } & HowlOptions
}

// [audioData.id, require(`@/views/game/data/${module}/assets/audios/${audioData.file}`), null, {
//     'preload': true,
//     'loop': !!audioData.loop,
//     'isMusic': !!audioData.isMusic
// }]


class AudioService {
    isMuted = false;
    music = new Music();
    fx = new Fx();

    constructor() {
    }

    mute = () => {
        this.isMuted = true;
        Howler.mute(true);
    }

    unmute = () => {
        this.isMuted = false;
        Howler.mute(false);
    }

    toggle = () => Howler.mute(!this.isMuted);

    async loadAudios(audioList: AudioOptions[]): Promise<void[]> {
        console.debug(0, `AudioService : loadAudios`, audioList);

        function getLoadAudioPromise(_audio: Audio): Promise<void> {
            return new Promise((resolve, reject) => {
                // resolve
                _audio.once('load', () => resolve());
                // reject
                _audio.once('loaderror', (code, message) => {
                    console.error(_audio.name, code, message);
                    reject({_audio, code, message});
                });
            });
        }

        const audioLoadPromises = [];

        // Create audio promise for each audio in list
        for (const audioData of audioList) {
            const params = audioData.params || {};

            /* Howler format */
            const urls = audioData.url;
            // for (let i = 0; i < audioData[2].length; ++i) {
            //   urls.push(
            //     audioData[1] + '.' + audioData[2][i],
            //   );
            // }
            // for (const audioPath of audioData[1]) {
            //   urls.push(audioPath);
            // }

            const audio = new Audio({
                src: urls,
                autoplay: params.autoplay || false,
                loop: params.loop || false,
                sprite: params.sprite || {},
                html5: params.html5 !== undefined ? params.html5 : false,
                preload: params.preload !== undefined ? params.preload : true,
                mute: params.mute || false,
                rate: params.rate || 1,
                pool: params.pool || 1,
            });

            audio.name = audioData.id || 'noname';
            audio.volume(
                params.volume || params.isMusic ? this.music.volume : this.fx.volume,
            );

            // If already loaded
            if (this.music.get(audio.name) || this.fx.get(audio.name)) {
                console.debug(0, `AudioService : loadAudios : ${audio.name} is already loaded`)
            }
            else {
                console.debug(0, `AudioService : loadAudios : ${audio.name}`)

                if (params.isMusic) {
                    this.music.add(audio);
                }
                else {
                    this.fx.add(audio);
                }

                audioLoadPromises.push(getLoadAudioPromise(audio));
            }
        }

        return Promise.all(audioLoadPromises);
    };


    /**
     * set the global volume (FX is 75% of musics by default)
     * @memberOf AudioService
     * @protected
     * @param {Number} musicValue - from 0 (0%) to 1 (100%)
     * @param {Number} fxValue - from 0 (0%) to 1 (100%), if nothing musicValue*0.75 is used
     * @param {String} sign - used to increment or decrement current volume with the value passed (+ or - in a string)
     */
    setVolume(musicValue: number, fxValue: number, sign?: string) {
        if (sign == '+') {
            this.music.setVolume(this.music.volume + musicValue);
            this.fx.setVolume(this.fx.volume + (fxValue || musicValue * 0.75));
        }
        else if (sign == '-') {
            this.music.setVolume(this.music.volume - musicValue);
            this.fx.setVolume(this.fx.volume - (fxValue || musicValue * 0.75));
        }
        else {
            this.music.setVolume(musicValue);
            this.fx.setVolume(fxValue || musicValue * 0.75);
        }
        return this;
    };

}


class Music {
    _musics: any = {};
    volume = 0.75;

    constructor() {
    }

    /**
     * get all musics, an other way to access (instead of AudioService.music._musics )
     * @memberOf AudioService.music
     * @public
     */
    getAll() {
        return this._musics;
    };

    /**
     * get a specific music
     * an other way to access the instance of howler (instead of AudioService.music._musics[ name ] )
     * @memberOf AudioService.music
     * @public
     * @param {string} name - name of the music
     */
    get(name: string) {
        return this._musics[name];
    };

    /**
     * add a music to the library
     * @memberOf AudioService.music
     * @public
     * @param {Audio} music - instance of Audio (must have a .name)
     */
    add(music: Audio) {
        this._musics[music.name] = music;
        return this;
    };

    /**
     * play the given music
     * @memberOf AudioService.music
     * @public
     * @param {String} name - name of the music to play
     * @param {String} sprite - name of the sprite to play (optional)
     */
    play(name: string, sprite?: string) {
        if (!this._musics[name]) {
            console.error('AudioService.music: not declared: ' + name + ' - ' + sprite);
            return;
        }

        // if the sound was preload = false, it must be loaded now !
        if (this._musics[name]._sounds.length === 0) {
            this._musics[name].load();
        }

        this._musics[name].play(sprite);
        return this;
    };

    /**
     * pause all instance of the given music
     * @memberOf AudioService.music
     * @public
     * @param {String} name - name of the music to pause
     */
    pause(name: string) {
        if (!this._musics[name]) {
            console.error('Audios.music: not declared: ' + name);
            return;
        }

        this._musics[name].pause();
        return this;
    };

    /**
     * stop all instance of the given music
     * @memberOf AudioService.music
     * @public
     * @param {String} name - name of the music to stop
     */
    stop(name: string) {
        if (!this._musics[name]) {
            console.error('Audios.music: not declared: ' + name);
            return;
        }

        this._musics[name].stop();
        return this;
    };

    /****
     * stop all musics and play one, can preserve specific musics
     * @memberOf AudioService.music
     * @public
     * @param {String} name - name of the music to play
     * @param {String} sprite - name of the sprite to play (optional)
     * @param {String or Array of String} preserve - name of music to preserve (can be an array)
     * @example: AudioService.music.stopAllAndPlay( "game", null, "ambiance" )
     */
    stopAllAndPlay(name: string, sprite?: string, preserve?: string | string[]) {
        this.stopAll(preserve);
        if (name != preserve) {
            this.play(name, sprite);
        }

        return this;
    };

    /****
     * stop all musics to play "name", can preserve a specific music
     * @memberOf AudioService.music
     * @public
     * @param {String or Array of String} preserve - name of music to preserve (can be an array)
     * @example: AudioService.music.stopAll( [ "bossFight", "lavaExplosions" ] )
     */
    stopAll(preserve?: string | string[]) {
        if (!preserve) preserve = [];
        if (!Array.isArray(preserve)) preserve = [preserve];

        for (const m in this._musics) {
            if (preserve.indexOf(m) != -1) {

            }
            else {
                this._musics[m].stop();
            }
        }
        return this;
    };

    pauseAll(preserve?: string | string[]) {
        if (!preserve) preserve = [];
        if (!Array.isArray(preserve)) preserve = [preserve];

        for (const m in this._musics) {
            if (preserve.indexOf(m) != -1) {

            }
            else {
                this._musics[m].pause();
            }
        }
        return this;
    };

    pauseAllAndPlay(name: string, sprite: string, preserve: string) {
        this.pauseAll(preserve);
        if (name != preserve) {
            this.play(name, sprite);
        }
        return this;
    };

    /**
     * change mute state for all musics
     * @memberOf AudioService.music
     * @public
     * @param {Boolean} state - mute or unmute
     */
    mute(state: boolean) {
        for (const m in this._musics) {
            this._musics[m].mute(state);
        }
        return this;
    };

    /**
     * set a global volume for every musics
     * @memberOf AudioService.music
     * @public
     * @param {Number} val - from 0 (0%) to 1 (100%)
     * @param {Boolean} usePercent - if true, this will use the value as a coef for each musics (example: bullet.volume is 0.7, you call setVolume with 0.5 then bullet.volume is 0.35)
     */
    setVolume(val: number, usePercent?: boolean) {
        this.volume = usePercent ? this.volume * val : val;

        for (const i in this._musics) {
            if (usePercent) {
                this._musics[i].volume(this._musics[i].volume() * val);
            }
            else {
                this._musics[i].volume(val);
            }
        }
        return this;
    };

}


class Fx {
    _fxs: any = {};
    volume = 0.75;

    constructor() {
    }

    /**
     * get a specific fx
     * an other way to access the instance of howler (instead of AudioService.fx._fxs[ name ] )
     * @memberOf AudioService.fx
     * @public
     * @param {string} name - name of the fx
     */
    get(name: string) {
        return this._fxs[name];
    };

    /**
     * add a fx to the library
     * @memberOf AudioService.fx
     * @public
     * @param {Howl} fx - instance of Howl (must have a .name)
     */
    add(fx: Audio) {
        this._fxs[fx.name] = fx;
        return this;
    };

    /**
     * play the given fx
     * @memberOf AudioService.fx
     * @public
     * @param {String} name - name of the fx to play
     * @param {String} sprite - name of the sprite to play (optional)
     */
    play(name: string, sprite?: string) {
        if (!this._fxs[name]) {
            return;
        }

        // if the sound was preload = false, it must be loaded now !
        if (this._fxs[name]._sounds.length === 0) {
            this._fxs[name].load();
        }

        this._fxs[name].play(sprite);
        return this;
    };

    /**
     * play randomly one of the given fx
     * @memberOf AudioService.fx
     * @public
     * @param {Array of String} name - array of name to choose randomly (can be a string, and the sprite is an array)
     * @param {Array of String} sprite - sprite to choose randomly if the name is a string
     */
    playRandom(name: string | string[], sprite: string[]) {
        let rand;

        if (Array.isArray(name)) {
            rand = (Math.random() * name.length) >> 0;
            this.play(name[rand]);
        }
        else {
            rand = (Math.random() * sprite.length) >> 0;
            this.play(name, sprite[rand]);
        }
    };

    /**
     * stop every instance of this fx (including sprites)
     * @memberOf AudioService.fx
     * @public
     * @param {String} name - fx to stop
     */
    stop(name: string) {
        this._fxs[name].stop();
    };

    /****
     * stop all musics and play one, can preserve specific musics
     * @memberOf AudioService.music
     * @public
     * @param {String} name - name of the music to play
     * @param {String} sprite - name of the sprite to play (optional)
     * @param {String or Array of String} preserve - name of music to preserve (can be an array)
     * @example: AudioService.music.stopAllAndPlay( "game", null, "ambiance" )
     */
    stopAllAndPlay(name: string, sprite?: string, preserve?: string | string[]) {
        this.stopAll(preserve);
        if (name != preserve) {
            this.play(name, sprite);
        }

        return this;
    };

    /****
     * stop all musics to play "name", can preserve a specific music
     * @memberOf AudioService.music
     * @public
     * @param {String or Array of String} preserve - name of music to preserve (can be an array)
     * @example: AudioService.music.stopAll( [ "bossFight", "lavaExplosions" ] )
     */
    stopAll(preserve?: string | string[]) {
        if (!preserve) preserve = [];
        if (!Array.isArray(preserve)) preserve = [preserve];

        for (const f in this._fxs) {
            if (preserve.indexOf(f) != -1) {

            }
            else {
                this._fxs[f].stop();
            }
        }
        return this;
    };

    /**
     * change mute state for all fx
     * @memberOf AudioService.fx
     * @public
     * @param {Boolean} state - mute or unmute
     */
    mute(state: boolean) {
        for (const m in this._fxs) {
            this._fxs[m].mute(state);
        }
        return this;
    };

    /**
     * set a global volume for every fx
     * @memberOf AudioService.fx
     * @public
     * @param {Number} value - from 0 (0%) to 1 (100%)
     * @param {Boolean} useAsCoef - if true, this will use the value as a coef for each fx (example: bullet.volume is 0.7, you call setVolume with 0.5 then bullet.volume is 0.35)
     */
    setVolume(value: number, useAsCoef?: boolean) {
        this.volume = useAsCoef ? ((this.volume * value * 100) >> 0) / 100 : value;

        for (const i in this._fxs) {
            if (useAsCoef) {
                this._fxs[i].volume(this._fxs[i].volume() * value);
            }
            else {
                this._fxs[i].volume(value);
            }
        }
        return this;
    };
}

export default new AudioService();

import {exerciseFragment, ExerciseNode} from '@/models/exercise.node';
import {DbObject} from '@/types/neo4j';
import gql from 'graphql-tag';

export interface MustTrainDbRelation extends DbObject {
    done: boolean
}

export interface MustTrainRelation extends DbObject, MustTrainDbRelation {
    node: ExerciseNode;
}

export const mustTrainFragment = `
    _id
    _ownerId
    _createdAt
    _updatedAt
    
    done
`;


export const mustTrainFetchAllLiteQuery = gql`
    query mustTrainFetchAllLiteQuery(
        $email : String!
    ) {
        players( where: { email: $email } ) {
            relMustTrainExerciseConnection {
                edges {
                    ${mustTrainFragment}
                }
            }
        }
    }
`;


export const mustTrainFetchAllQuery = gql`
    query mustTrainFetchAllQuery(
        $email : String!
    ) {
        players( where: { email: $email } ) {
            _id
            currentTrainingId

            relMustTrainExerciseConnection {
                edges {
                    ${mustTrainFragment}

                    node {
                        ${exerciseFragment}
                    }
                }
            }
        }
    }
`;


// export const mustTrainUpdateQuery = gql`
//     mutation mustTrainUpdateQuery(
//         $relationId: ID!,
//         $relMustTrainUpdateInput: RelMustTrainUpdateInput!
//     ) {
//         updatePlayers(
//             update: {
//                 relMustTrainExercise: [
//                     {
//                         where: { edge: { _id: $relationId } }
//                         update: { edge: $relMustTrainUpdateInput }
//                     }
//                 ]
//             }
//         ) {
//             players {
//                 _id
//
//                 relMustTrainExerciseConnection {
//                     edges {
//                         ${mustTrainFragment}
//
//                         node {
//                             ${exerciseFragment}
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `;


export const mustTrainUpdateOrCreateByIdsQuery = gql`
    mutation mustTrainUpdateOrCreateByIdsQuery (
        $playerId: ID!,
        $exerciseId: ID!,
        $relCreateInput: RelMustTrainCreateInput!
    ) {
        updatePlayers(
            where: { _id: $playerId },
            connect: {
                relMustTrainExercise: [
                    {
                        where: {
                            node: {
                                _id: $exerciseId
                            }
                        },
                        edge: $relCreateInput
                    }
                ]
            }) {
            players {
                _id

                relMustTrainExerciseConnection {
                    edges {
                        ${mustTrainFragment}

                        node {
                            ${exerciseFragment}
                        }
                    }
                }
            }
        }
    }
`;




























































































































import {ExerciseNode} from '@/models/exercise.node';
import {notionFetchQuery, NotionLiteNode, NotionNode} from '@/models/notion.node';
import {PlayerConceivedTrainingsNotionsNode, playerFetchConceivedTrainingNotionsQuery} from '@/models/player.node';
import {TrainingNotionsNode} from '@/models/training.node';
import {Localization, localizationQuery} from '@/services/localization.service';
import {Item} from '@/types/questions.vue';
import QuestionExercise from '@/views/questions/QuestionsExercise.component.vue';
import {clone, cloneDeep} from 'lodash';
import {Component, Vue} from 'vue-property-decorator';


export interface RefreshTreeviewOptions {
  cacheOnly?: boolean
  onlyFetchNotionId?: string
  // refreshTreeViewUpdateAll?: boolean
}


@Component({
  name: 'Questions',
  components: {
    QuestionExerciseForm: QuestionExercise
  },
  apollo: {
    player: {
      query: playerFetchConceivedTrainingNotionsQuery,
      fetchPolicy: 'cache-first',
      update: data => data?.players?.length ? data?.players[0] : undefined,
      result() {
        this.refreshItems({cacheOnly: true});  // Init TreeView
      },
      skip() {
        return !!this.player;
      },
    },
    localizations: {
      query: localizationQuery,
      fetchPolicy: 'cache-only',
      result() {
        this.showItemName = false;
        this.showItemName = true;
      },
    },
  },
})
export default class Questions extends Vue {
  player: PlayerConceivedTrainingsNotionsNode | undefined;
  localizations: Localization | undefined;

  notions: NotionNode[] = [];
  items: Item[] = [];
  showItemName = true;

  deleteDialog = false;
  deletedItem: Item | null = null;
  isDeleteDialogLoading = false;


  mounted() {
    this.$root.$emit('navigationEvent', {color: 'secondary', title: this.$loc('Questions.title')});
  }


  // --- TreeView

  refreshTreeView(_options: RefreshTreeviewOptions = {}) {
    console.debug(0, 'Questions : refreshTreeView', _options);
    // -- Update treeview
    this.refreshItems(_options);

    // if (this.$refs.treeviewRef) {
    // @ts-ignores
    // this.$refs.treeviewRef.updateAll(false);
    // this.$refs.treeviewRef.updateAll(!!_options.refreshTreeViewUpdateAll);
    // }
  }


  refreshItems(_options: RefreshTreeviewOptions = {}) {
    console.debug(0, 'QuestionsComponent : refreshItems : options', _options);

    if (!this.player?._id) this.items = [];
    else {
      const data: Item[] = [];

      for (const relTrainedFor of this.player.relConceivesTrainingConnection.edges) {
        const training: TrainingNotionsNode = relTrainedFor.node;
        // console.debug(0, 'QuestionsComponent : refreshItems : training', training);

        const item: Item = {
          id: training._id,
          type: 'training',
          training: clone(training),
          textId: 'Trainings.' + training.name,
          // name: this.$loc('Trainings.' + training.name),
          children: []
        };

        if (!item.children) return;  // typescript

        for (const relInstructs of training.relInstructsNotionsConnection.edges) {
          const notion: NotionLiteNode = relInstructs.node;
          const notionItem: Item = {
            id: notion._id,
            type: 'notion',
            training: clone(training),
            notion: clone(notion),
            textId: 'Notions.' + notion.name,
            // name: this.$loc('Notions.' + notion.name),
            children: []
          };
          item.children.push(notionItem);

          // Add already fetched Notions
          // const options = merge({cacheOnly: true}, _options);
          this.fetchNotionItem(notionItem, _options);
        }

        data.push(item);
      }

      this.items = data;
    }
  }


  async fetchNotionItem(_notionItem: Item, _options: RefreshTreeviewOptions = {}): Promise<any> {
    console.debug(0, 'Questions : fetchNotionItem : params', _notionItem, _options);
    if (_notionItem.type !== 'notion') return;

    // Fetch notion from data or server
    let notion: NotionNode | undefined = this.notions.find(_notion => _notion._id === _notionItem.notion?._id)

    if (!_options.cacheOnly) {
      if (!_options.onlyFetchNotionId || _options.onlyFetchNotionId === _notionItem.notion?._id) {
        const result = await this.$apollo.query({
          query: notionFetchQuery,
          fetchPolicy: 'network-only',
          variables: {
            notionId: _notionItem.notion?._id
          }
        });

        notion = result?.data?.notions[0];
        if (!notion) return console.error('Questions.vue : fetchItem : apollo notion result is undefined');

        this.notions = this.notions.filter(_notion => _notion._id !== notion?._id);
        this.notions.push(cloneDeep(notion));

        console.debug(0, 'Questions : fetchNotionItem : network', cloneDeep(notion), cloneDeep(this.notions));
      }
    }

    if (!notion?.relTestsExercisesConnection?.edges) return;

    // Add notion's question items
    _notionItem.children = [];
    notion.relTestsExercisesConnection.edges.forEach((relTests, index) => {
      const exercise: ExerciseNode = relTests.node;

      // Add exercise / question
      const questionItem: Item = {
        id: 'Quiz_' + exercise._id,
        type: 'question',
        training: clone(_notionItem.training),
        notion: clone(_notionItem.notion),
        exercise: clone(exercise),
        // name: `Q${index} : ` + (this.$loc('Exercises.' + exercise.content.questionId) || '...'),
        textId: 'Exercises.' + exercise.content.questionId,
        children: []
      }

      // Add answers
      exercise.content.answers.forEach((answer, index) => {
        if (questionItem.children && answer.textId) {
          questionItem.children.push({
            id: 'Quiz_' + answer._id,
            type: 'answer',
            training: clone(_notionItem.training),
            notion: clone(_notionItem.notion),
            exercise: clone(exercise),
            // name: answer._id,
            // name: this.$loc('Exercises.' + answer.textId) || '...',
            textId: 'Exercises.' + answer.textId,
          });
        }
      });

      // Add explanation
      if (questionItem.children && exercise.content.explanationId) {
        questionItem.children.push({
          id: 'Quiz_' + exercise.content.explanationId,
          type: 'explanation',
          training: clone(_notionItem.training),
          notion: clone(_notionItem.notion),
          exercise: clone(exercise),
          textId: 'Exercises.' + exercise.content.explanationId,
          // name: this.$loc('Exercises.' + exercise.content.explanationId) || '...',
        });
      }

      // Add exercises buttons (modify and delete)
      if (questionItem.children) {
        questionItem.children.push({
          id: exercise._id,
          type: 'editExerciseButtons',
          training: clone(_notionItem.training),
          notion: clone(_notionItem.notion),
          exercise: clone(exercise),
          // name: this.$loc('Questions.edit'),
          textId: '',
        });
      }

      !!_notionItem.children && _notionItem.children.push(questionItem);          // typescript && Add notion's exercise into children
    });

    _notionItem.children.push({
      id: 'AddNotionExercise_' + _notionItem.notion?._id,
      type: 'createExerciseButton',
      training: clone(_notionItem.training),
      notion: clone(_notionItem.notion),
      // name: this.$loc('Questions.edit'),
      textId: '',
    });

    console.debug(0, 'Questions : fetchNotionItem : result items', cloneDeep(this.items));
  }


  // --- Delete Dialog

  openDeleteDialog(_item: Item) {
    this.isDeleteDialogLoading = false;
    this.deleteDialog = true;
    this.deletedItem = _item;
  }


  onCloseDeleteDialog() {
    this.isDeleteDialogLoading = false;
    this.deleteDialog = false;
    this.deletedItem = null;
  }


  async onSubmitDeleteDialog(): Promise<void> {
    console.debug(0, 'Questions : onSubmitDeleteDialog : deletedItem', this.deletedItem);


    if (this.deletedItem?.training && this.deletedItem?.notion && this.deletedItem?.exercise) {   // typescript
      if (this.deletedItem.type === 'editExerciseButtons') {
        this.isDeleteDialogLoading = true;

        try {
          // -- Remove exercise in DB and Localization files
          const questionExerciseComponent = this.$refs.questionExerciseComponent as QuestionExercise;
          await questionExerciseComponent.deleteExercise(this.deletedItem.training, this.deletedItem.notion, this.deletedItem.exercise);

          // -- Refresh View Items
          const options: RefreshTreeviewOptions = {
            onlyFetchNotionId: this.deletedItem.notion._id
          }
          this.refreshTreeView(options);
        } catch (e) {
          this.onCloseDeleteDialog();
          console.error('Questions : onSubmitDeleteDialog : deletedItem is incorrect', e);
        }
      }
    }
    else {
      console.error('Questions : onSubmitDeleteDialog : deletedItem is incorrect', this.deletedItem);
    }

    this.onCloseDeleteDialog();
  }
}

import {ExerciseNode} from '@/models/exercise.node';
import {DbObject} from '@/types/neo4j';

export interface TestsDbRelation extends DbObject {
}

export interface TestsRelation extends DbObject, TestsDbRelation {
    node: ExerciseNode;
}

export const testsFragment = `
    _id
    _ownerId
    _createdAt
    _updatedAt
`;

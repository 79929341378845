import axios from 'axios';
import {getDownloadURL, getStorage, ref, uploadBytes, UploadResult} from 'firebase/storage';


export async function downloadJsonFile(_filePath: string): Promise<any> {
    try {
        // initializeFirebase();

        const storage = getStorage();
        const fileRef = ref(storage, _filePath);
        const fileUrl = await getDownloadURL(fileRef);

        // console.debug(0, 'FirebaseService : downloadFile : fileUrl', fileUrl);

        const response = await axios({
            url: fileUrl,
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            },
        });

        // console.debug(0, 'FirebaseService : downloadFile', response);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
}


export async function uploadJsonFile(_filePath: string, _object: any): Promise<UploadResult> {
    try {
        // initializeFirebase();

        const storage = getStorage();
        const fileRef = ref(storage, _filePath);

        const str = JSON.stringify(_object);
        const bytes = new TextEncoder().encode(str);
        const blob = new Blob([bytes], {
            type: 'application/json;charset=utf-8'
        });

        const snapshot: UploadResult = await uploadBytes(fileRef, blob);
        return snapshot;
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
}

import {NotionLiteNode, NotionNode} from '@/models/notion.node';
import {DbObject} from '@/types/neo4j';

export interface InstructsDbRelation extends DbObject {
}

export interface InstructsRelation extends DbObject, InstructsDbRelation {
    node: NotionNode;
}

export interface InstructsLiteRelation extends DbObject, InstructsDbRelation {
    node: NotionLiteNode;
}

export const instructsFragment = `
    _id
    _ownerId
    _createdAt
    _updatedAt
`;

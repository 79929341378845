import StoreService from '@/services/store.service';
import {ApolloLink, Observable, Operation} from 'apollo-link';


const hasQueryRequiresVariable = (operation: Operation, variableName: string) =>
    operation.query.definitions?.some((definitionNode) => {
            if ('variableDefinitions' in definitionNode) {
                return definitionNode.variableDefinitions?.some(({variable}) => variable.name.value === variableName)
            }
            return false;
        }
    );


const injectVariables = async (operation: Operation) => {
    if (hasQueryRequiresVariable(operation, 'email') && StoreService.states.user?.email) {
        operation.variables['email'] = StoreService.states.user?.email;
    }
};


export const variableInjectionLink = new ApolloLink(
    (operation, forward) =>
        new Observable(observer => {
            let handle: any;
            Promise.resolve(operation)
                .then(operation => injectVariables(operation))
                .then(() => {
                    handle = forward(operation).subscribe({
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer)
                    });
                })
                .catch(observer.error.bind(observer));

            return () => {
                if (handle) handle.unsubscribe();
            };
        })
);

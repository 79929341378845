import {NotionNode} from '@/models/notion.node';
import {DbObject} from '@/types/neo4j';
import gql from 'graphql-tag';

export interface KnowsDbRelation extends DbObject {
    xp: number
}

export interface KnowsRelation extends DbObject, KnowsDbRelation {
    node: NotionNode;
}

export const knowsFragment = `
    _id
    _ownerId
    _createdAt
    _updatedAt
    
    xp
`;


// export const knowsUpdateQuery = gql`
//     mutation knowsUpdateQuery(
//         $relationId: ID!,
//         $relKnowsUpdateInput: RelKnowsUpdateInput!
//     ) {
//         updatePlayers(
//             update: {
//                 relKnowsNotions: [
//                     {
//                         where: { edge: { _id: $relationId } }
//                         update: { edge: $relKnowsUpdateInput }
//                     }
//                 ]
//             }
//         ) {
//             players {
//                 _id
//
//                 relKnowsNotionsConnection {
//                     edges {
//                         ${knowsFragment}
//
//                         node {
//                             ${notionFragment}
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `;


export const knowsUpdateOrCreateByIdsQuery = gql`
    mutation knowsUpdateOrCreateByIdsQuery (
        $playerId: ID!,
        $notionId: ID!,
        $relCreateInput: RelKnowsCreateInput!
    ) {
        updatePlayers(
            where: { _id: $playerId },
            connect: {
                relKnowsNotions: [
                    {
                        where: {
                            node: {
                                _id: $notionId
                            }
                        },
                        edge: $relCreateInput
                    }
                ]
            }) {
            players {
                _id

                relKnowsNotionsConnection {
                    edges {
                        ${knowsFragment}

                        node {
                            _id
                        }
                    }
                }
            }
        }
    }
`;









































































































































































import {ManagedPlayerNode} from '@/models/managedPlayer.node';
import {ManagesLiteRelation} from '@/models/manages.relation';
import {playerFetchManagedPlayersQuery, PlayerManagesPlayersNode} from '@/models/player.node';
import {Localization, localizationQuery} from '@/services/localization.service';
import {ApexOptions} from 'apexcharts';
import {groupBy, sortBy, sumBy} from 'lodash';
import {Component, Vue} from 'vue-property-decorator';


@Component({
  name: 'Stats',
  components: {},
  apollo: {
    playerManagesPlayers: {
      query: playerFetchManagedPlayersQuery,
      update: data => data?.players?.length ? data?.players[0] : undefined,
      fetchPolicy: 'cache-first',
    },
    localizations: {
      query: localizationQuery,
      fetchPolicy: 'cache-only',
    },
  },
})
export default class Stats extends Vue {
  playerManagesPlayers: PlayerManagesPlayersNode | undefined;
  localizations: Localization | undefined;


  selectedManagesRelation: ManagesLiteRelation | null = null;

  donutsOptions: ApexOptions = {
    legend: {
      // show: false
    },
    labels: [],
  };


  // get user(): UserModel | undefined {
  //   // console.debug(0, 'Stats : user', StoreService.states.user.customClaims['admin']);
  //   return StoreService.states.user;
  // }


  // labels: string[] = [];
  get orderedManagesRelations(): ManagesLiteRelation[] {
    const relations = this.playerManagesPlayers?.relManagesPlayersConnection?.edges;

    // console.debug(0, 'Stats : orderedManagesRelations', relations?.filter(rel => rel.node.pseudo === "Fabien FELIX"));

    return relations ? sortBy(relations, function (o) {
      return o.node.lastname;
    }) : [];
  }


  get totalAppTimeSpent(): number {
    if (!this.orderedManagesRelations.length) return 0;

    function getPlayerAppTimeSpent(rel: ManagesLiteRelation) {
      return rel.node.appTimeSpent;
    }

    return sumBy(this.orderedManagesRelations, getPlayerAppTimeSpent);
  }


  get totalMinutes(): number {
    if (!this.orderedManagesRelations.length) return 0;

    return Math.floor((this.totalAppTimeSpent / (1000 * 60)) % 60);
  }


  get totalHours(): number {
    if (!this.orderedManagesRelations.length) return 0;

    return Math.floor(this.totalAppTimeSpent / (1000 * 60 * 60));
  }


  get series(): any[] {
    const managesRelations = this.playerManagesPlayers?.relManagesPlayersConnection?.edges;
    if (!managesRelations) return [];

    const endedChaptersByPlayers: number[] = [];

    managesRelations.forEach(relManagesPlayer => {
      const managedPlayer: ManagedPlayerNode = relManagesPlayer.node;
      const relEndedModelsLength = managedPlayer.relEndedChaptersConnection?.edges.length || 0;
      endedChaptersByPlayers.push(relEndedModelsLength);
    })

    const groupedEndedChaptersObject = groupBy(endedChaptersByPlayers, Math.floor); // example : [0, 0, 1, 2, 2 ] => {0: [0, 0], 1: [1], 2: [2, 2]}

    const series = [];
    this.donutsOptions.labels = [];
    for (const [key, value] of Object.entries(groupedEndedChaptersObject)) {
      series.push(value.length);
      this.donutsOptions.labels.push(`${this.$loc('Stats.chapter')} ${key}`);
    }

    // console.debug(0, 'Stats : series', groupedEndedChaptersObject, series);

    return series;
  }


  mounted() {
    this.$root.$emit('navigationEvent', {color: 'secondary', title: this.$loc('Stats.title')});
  }


  getSelectedItemName(_item: ManagesLiteRelation): string {
    return `${_item.node.lastname} ${_item.node.firstname}`
    // return `${_item.node.lastname} ${_item.node.firstname}  # ${_item.node.pseudo}`
  }

  // selectAll() {
  //   this.selectedManagesRelation = null;
  // }
}

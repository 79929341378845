

















import {Component, Vue} from 'vue-property-decorator';

export interface toastEventOptions {
  text: string;
  closeTimeout?: number;
  type?: 'success' | 'info' | 'warning' | 'error';
}

@Component({
  name: 'ErrorToast',
  components: {}
})
export default class ErrorToast extends Vue {
  show = false;
  text = '';
  type = 'error';

  created() {
    this.$root.$on('toastEvent', (_options: toastEventOptions) => {
      console.debug(0, 'ErrorToast : show', _options);
      this.show = true;
      this.text = _options.text;
      this.type = _options.type || 'error';

      if (_options.closeTimeout) setTimeout(() => this.show = false, _options.closeTimeout);
    });
  }
}

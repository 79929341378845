import {playerFragment} from '@/models/player.node';
import {initializeFirebase} from '@/services/firebase.service';
import StoreService from '@/services/store.service';
import {VueService} from '@/services/vue.service';
import {UserCredential} from '@firebase/auth/dist/node-esm';
import {User} from '@firebase/auth/dist/node-esm/src/model/public_types';
import {browserLocalPersistence, getAuth, onAuthStateChanged, sendPasswordResetEmail, setPersistence, signInWithEmailAndPassword} from 'firebase/auth';
import gql from 'graphql-tag';
import {merge, pick} from 'lodash';


export interface FirebaseUserModel extends User {
    admin?: boolean
}

export interface UserModel {
    uid: string
    email: string
    admin?: boolean
}

export const userFetchQuery = gql`
    query userFetchQuery{
        user {
            uid
            email
            admin
        }
    }
`;


class UserService extends VueService {
    constructor() {
        super();
    }


    initialize() {
        console.debug(1, 'UserService : initialize');

        // Initialize Firebase
        // initializeFirebase();
        const auth = getAuth();

        // Start auth subscription
        onAuthStateChanged(auth, (user) => {
            console.debug(0, 'onAuthStateChanged : user firebase', user);

            if (user?.uid) {
                user.getIdTokenResult()
                    .then((idTokenResult) => {
                        const firebaseUserModel: FirebaseUserModel = merge(user, idTokenResult.claims) as FirebaseUserModel;
                        const userModel : UserModel = pick(firebaseUserModel, ['uid', 'email', 'admin']) as UserModel;

                        console.debug(0, 'onAuthStateChanged : firebaseUserModel', firebaseUserModel);
                        console.debug(0, 'onAuthStateChanged : userModel', userModel);

                        // Update store
                        StoreService.onUserStateChange(userModel);

                        this.$vue.$router.replace({name: 'Advancement', query: this.$vue.$route.query});
                    })
                    .catch((error) => console.log(error));
            }
            else this.$vue.$router.replace({name: 'Login', query: this.$vue.$route.query});
        });
    }


    async authenticated(_email: string, _password: string): Promise<UserCredential> {
        const auth = getAuth();

        return setPersistence(auth, browserLocalPersistence)
            .then(() => {
                // Existing and future Auth states are now persisted in the current
                // session only. Closing the window would clear any existing state even
                // if a user forgets to sign out.
                // ...
                // New sign-in will be persisted with session persistence.
                return signInWithEmailAndPassword(auth, _email, _password)
            })
    }


    async resetPassword(_email: string): Promise<void> {
        const auth = getAuth();

        return sendPasswordResetEmail(auth, _email);
    }


    async signOut(): Promise<void> {
        return getAuth().signOut();
    }


    getErrorMessage(errorCode: string, errorMessage: string): string {
        // TODO : use locals
        console.warn(errorCode, errorMessage);

        let userMessage = '';
        if (errorCode === 'auth/wrong-password') {
            userMessage = 'Le mot de passe est incorrect';
        }
        else if (errorCode === 'auth/invalid-email') {
            userMessage = 'Cette adresse email est invalide';
        }
        else if (errorCode === 'auth/user-disabled') {
            userMessage = 'Ce compte utilisateur est désactivé';
        }
        else if (errorCode === 'auth/user-not-found') {
            userMessage = 'Il n\'y a pas de compte associé à cette adresse email, veuillez vérifier l\'email saisi';
        }
        else if (errorCode === 'auth/expired-action-code') {
            userMessage = 'Ce code de validation est expiré';
        }
        else if (errorCode === 'auth/invalid-action-code') {
            userMessage = 'Ce code de validation a déjà été utilisé ou est incorrect';
        }
        else if (errorCode === 'auth/weak-password') userMessage = 'Ce mot de passe n\'est pas assez complexe';

        return userMessage;
    }
}

export default new UserService();

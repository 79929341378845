import {exerciseFragment, ExerciseNode} from '@/models/exercise.node';
import {DbObject} from '@/types/neo4j';
import gql from 'graphql-tag';

export interface TookDbRelation extends DbObject {
    succeed: boolean
}

export interface TookLiteRelation extends TookDbRelation {
}

export interface TookRelation extends TookDbRelation {
    node: ExerciseNode;
}

export const tookFragment = `
    _id
    _ownerId
    _createdAt
    _updatedAt
    
    succeed
`;


// export const tookUpdateQuery = gql`
//     mutation tookUpdateQuery(
//         $relationId: ID!,
//         $relTookUpdateInput: RelTookUpdateInput!
//     ) {
//         updatePlayers(
//             update: {
//                 relTookExercise: [
//                     {
//                         where: { edge: { _id: $relationId } }
//                         update: { edge: $relTookUpdateInput }
//                     }
//                 ]
//             }
//         ) {
//             players {
//                 _id
//
//                 relTookExerciseConnection {
//                     edges {
//                         ${tookFragment}
//
//                         node {
//                             ${exerciseFragment}
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `;


export const tookUpdateOrCreateByIdsQuery = gql`
    mutation tookUpdateOrCreateByIdsQuery (
        $playerId: ID!,
        $exerciseId: ID!,
        $relCreateInput: RelTookCreateInput!
    ) {
        updatePlayers(
            where: { _id: $playerId },
            connect: {
                relTookExercise: [
                    {
                        where: {
                            node: {
                                _id: $exerciseId
                            }
                        },
                        edge: $relCreateInput
                    }
                ]
            }) {
            players {
                _id

                relTookExerciseConnection {
                    edges {
                        ${tookFragment}

                        node {
                            ${exerciseFragment}
                        }
                    }
                }
            }
        }
    }
`;

import {exerciseFragment} from '@/models/exercise.node';
import {testsFragment, TestsRelation} from '@/models/tests.relation';
import {DbObject} from '@/types/neo4j';
import gql from 'graphql-tag';


export interface NotionDbNode extends DbObject {
    name: string
}

export interface NotionLiteNode extends DbObject, NotionDbNode {
}

export interface NotionNode extends DbObject, NotionDbNode {
    relTestsExercisesConnection: {
        edges: TestsRelation[]
    }
}

export const notionFragment = `
    _id
    _ownerId
    _createdAt
    _updatedAt

    name
`;

export const currentNotionQuery = gql`
    query notionQuery {
        currentNotion @client {
            ${notionFragment}
        }
    }
`;


export const notionsLiteFetchAllQuery = gql`
    query notionsFetchAllQuery {
        notions {
            ${notionFragment}
        }
    }
`;


export const notionFetchQuery = gql`
    query notionFetchQuery(
        $notionId: ID!
    ) {
        notions(where: { _id: $notionId }) {
            ${notionFragment}
            
            relTestsExercisesConnection {
                 edges {
                    ${testsFragment}

                    node {
                        ${exerciseFragment}
                    }
                }
            }
        }
    }
`;

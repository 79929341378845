import {EndedLiteRelation, EndedRelation} from '@/models/ended.relation';
import {TookLiteRelation, TookRelation} from '@/models/took.relation';
import {DbObject} from '@/types/neo4j';


export interface ManagedPlayerDbNode extends DbObject {
    currentLevel: number
    currentXP: number

    pseudo: string
    firstname: string
    lastname: string

    appTimeSpent: number
    gameTimeSpent: number

    currentTrainingId: string
    currentChapterId: string

    lastExerciseDate: Date
    lastChapterDate: Date
    graduallyUnlockChapterDays?: number
}


export interface ManagedPlayerLiteNode extends ManagedPlayerDbNode {
}


export interface ManagedPlayerNode extends ManagedPlayerDbNode {
    relEndedChaptersConnection: {
        edges: EndedLiteRelation[]
    }
    relTookExerciseConnection: {
        edges: TookLiteRelation[]
    }
}


export const managedPlayerFragment = `
        _id
        _ownerId
        _createdAt
        _updatedAt
        
        pseudo
        firstname
        lastname
        
        currentLevel
        currentXP
        
        appTimeSpent
        gameTimeSpent
        
        currentTrainingId
        currentChapterId
        
        lastExerciseDate
        lastChapterDate
        graduallyUnlockChapterDays
`;

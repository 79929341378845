import {playerUpdateQuery} from '@/models/player.node';
import StoreService from '@/services/store.service';
import {VueService} from '@/services/vue.service';
import {TIME_TRACKING_MAX_TIME_SAVE} from '@/variables';
import {clone} from 'lodash';

// class TimeTrackingService extends VueService {
class TimeTrackingService extends VueService {

    startAppDate: Date | null = null;
    appTimeCounter = 0;

    startGameDate: Date | null = null;
    gameTimeCounter = 0;

    lastActivityDate: Date | null = null;

    initialize() {
        // console.debug(0, 'TimeTrackingService : startAppTimeTracking');
        // Add document click listener
        document.addEventListener('click', () => this.onLastActivityDateChange());
    }


    startAppTimeTracking() {
        // console.debug(0, 'TimeTrackingService : startAppTimeTracking');
        this.startAppDate = new Date();
        this.lastActivityDate = new Date();

        this.appTimeCounter = 0;
    }


    endAppTimeTracking() {
        // console.debug(0, 'TimeTrackingService : endAppTimeTracking');
        this.startAppDate = null;

        this.savePlayerTimeCounter();
    }


    startGameTimeTracking() {
        // console.debug(0, 'TimeTrackingService : startGameTimeTracking');
        this.startGameDate = new Date();

        this.gameTimeCounter = 0;
    }


    endGameTimeTracking() {
        // console.debug(0, 'TimeTrackingService : endGameTimeTracking');
        this.startGameDate = null;

        this.savePlayerTimeCounter();
    }


    onLastActivityDateChange() {
        let deltaTime = 0;
        const actualDate = new Date();

        // Check inactivity
        if (this.lastActivityDate) {
            deltaTime = Math.abs(actualDate.getTime() - this.lastActivityDate.getTime());

            const maxTimeInactivity = 60 * 1000; // 1min
            if (deltaTime > maxTimeInactivity) deltaTime = maxTimeInactivity;
        }

        if (this.startAppDate) this.appTimeCounter += deltaTime;
        if (this.startGameDate) this.gameTimeCounter += deltaTime;

        // console.debug(0, 'TimeTrackingService : onLastActivityDateChange', deltaTime, this.appTimeCounter, this.gameTimeCounter);
        this.lastActivityDate = actualDate;

        // Save each MAX_TIME_SAVE sec spent
        if (this.appTimeCounter > TIME_TRACKING_MAX_TIME_SAVE || this.gameTimeCounter > TIME_TRACKING_MAX_TIME_SAVE) this.savePlayerTimeCounter();
    }


    savePlayerTimeCounter() {
        if (!StoreService.states.player?._id) return Promise.resolve();

        const appTimeCounter = clone(this.appTimeCounter);
        this.appTimeCounter = 0;
        const gameTimeCounter = clone(this.gameTimeCounter);
        this.gameTimeCounter = 0;

        return this.$vue.$apollo.mutate({
            mutation: playerUpdateQuery,
            variables: {
                playerId: StoreService.states.player._id,
                playerUpdateInput: {
                    appTimeSpent: StoreService.states.player.appTimeSpent + appTimeCounter,
                    gameTimeSpent: StoreService.states.player.gameTimeSpent + gameTimeCounter,
                }
            }
        })
            .catch((err) => {
                this.appTimeCounter += appTimeCounter;
                this.gameTimeCounter += gameTimeCounter;
            })
    }
}

export default new TimeTrackingService();
















































import {chapterFetchQuery, ChapterNode} from '@/models/chapter.node';
import {ExerciseNode} from '@/models/exercise.node';
import {NotionNode} from '@/models/notion.node';
import {playerFetchQuery, PlayerNode} from '@/models/player.node';
import AppService from '@/services/app.service';
import ExerciseService, {
  getChapterMustAchieveExercicesWhereNotTookRelations, getChapterTeachesNotionTestsExercices,
  getChapterTeachesNotionTestsExercicesWhereNotTookRelations
} from '@/services/exercise.service';
import {Localization, localizationQuery, LocalizationService} from '@/services/localization.service';
import TimeTrackingService from '@/services/timeTracking.service';
import {SESSION_MAX_EXERCICES, SESSION_NUMBER_EXERCICES_FOR_ENCOURAGEMENTS} from '@/variables';
import SessionEncouragement from '@/views/session/SessionEncouragement.Component.vue';
import SessionQuiz from '@/views/session/SessionQuiz.Component.vue';
import SessionScore from '@/views/session/SessionScore.Component.vue';
import {cloneDeep, random} from 'lodash';
import {Component, Vue} from 'vue-property-decorator';
import {Route} from 'vue-router/types/router';


@Component({
  name: 'Session',
  components: {
    SessionEncouragement,
    SessionQuiz,
    SessionScore,
  },
  apollo: {
    player: {
      query: playerFetchQuery,
      // variables() {
      // return {
      // email: getAuth().currentUser?.email,
      // }
      // },
      update: data => data?.players?.length ? data?.players[0] : undefined,
      result() {
        this.fetchExerciseFirstTime();
      },
      fetchPolicy: 'cache-only',
    },
    localizations: {
      query: localizationQuery,
      fetchPolicy: 'cache-first',
    },
    // currentNotion: {
    //   query: currentNotionQuery, // local client query
    //   pollInterval: 5000, // reactive fix
    // },
    // training: {
    //   query: trainingFetchQuery,
    //   fetchPolicy: 'cache-first',
    //   update: data => data?.trainings?.length ? data?.trainings[0] : undefined,
    //   variables() {
    //     // Use vue reactive properties here
    //     return {
    //       trainingId: this.player?.currentTrainingId,
    //     }
    //   },
    //   skip() {
    //     return !this.player?.currentTrainingId
    //   },
    // },
    chapter: {
      query: chapterFetchQuery,
      fetchPolicy: 'cache-first',
      update: data => data?.chapters?.length ? data?.chapters[0] : undefined,
      variables() {
        // Use vue reactive properties here
        return {
          chapterId: this.player?.currentChapterId,
        }
      },
      result() {
        this.fetchExerciseFirstTime();
      },
      skip() {
        return !this.player?.currentChapterId
      },
    },
  }
})
export default class Session extends Vue {
  // @Prop({required: true}) chapter !: ChapterNode;

  player: PlayerNode | undefined;
  localizations: Localization | undefined;
  // training: TrainingNode | undefined;
  chapter: ChapterNode | undefined;
  exercise: ExerciseNode | null = null;

  trainingsLocalizationsLoaded = false;

  exercisesMode: string = '';

  // Encouragements component
  completedExercisesCount = 0;
  showEncouragement = false;

  // Score component
  sessionXp = 0;
  succeedExercisesCount = 0;
  sessionScore = 0;
  showScore = false;


  // get chapter(): ChapterNode | undefined {
  //   return (this.training && this.player?.currentChapterId) ? getChapterById(this.training, this.player.currentChapterId) : undefined;
  // }


  get progressValue(): number {
    if (!this.player || !this.chapter || !this.chapter.relMustAchieveExercisesConnection.edges) return 5;

    const ratio = getChapterMustAchieveExercicesWhereNotTookRelations(this.player, this.chapter).length /
        this.chapter.relMustAchieveExercisesConnection.edges.length;
    return Math.floor((1 - ratio) * 100);
  }


  created() {
    this.trainingsLocalizationsLoaded = LocalizationService.isTrainingLocalizationsLoaded()
    // this.$root.$on('onLocalizationStateChange',
    //     () => this.trainingsLocalizationsLoaded = LocalizationService.isTrainingLocalizationsLoaded());

    this.exercisesMode = this.$route.params.exercisesMode;
  }


  mounted() {
    this.$root.$emit('navigationEvent', {showNavBar: false});

    // Start time tracking
    TimeTrackingService.startGameTimeTracking();

    // Enable full screen for mobiles
    if (AppService.isMobile()) AppService.setFullScreen(true);
  }


  destroyed() {
    // End time tracking
    TimeTrackingService.endGameTimeTracking();

    // Disable full screen for mobiles
    if (AppService.isMobile()) AppService.setFullScreen(false);
  }


  fetchExerciseFirstTime(): void {
    if (this.player && this.chapter && !this.exercise) this.exercise = this.getExercise();
  }


  getExercise(): ExerciseNode | null {
    if (!this.chapter || !this.player) return null;

    // Priority 1 : mustAchieve not took exercises
    const mustAchieveRelationsForNotTookExercises = getChapterMustAchieveExercicesWhereNotTookRelations(this.player, this.chapter);
    if (mustAchieveRelationsForNotTookExercises.length) return mustAchieveRelationsForNotTookExercises[0].node;

    // Priority 2 : tests not took exercises
    const testsRelationsForNotTookExercises = getChapterTeachesNotionTestsExercicesWhereNotTookRelations(this.player, this.chapter);
    if (testsRelationsForNotTookExercises.length) return testsRelationsForNotTookExercises[0].node;

    // Priority 3 : random chapter exercises
    const testsRelations = getChapterTeachesNotionTestsExercices(this.player, this.chapter);
    if (testsRelations.length) return testsRelations[random(0, testsRelations.length - 1)].node;

    return null;

    // switch (this.exercisesMode) {
    //   case 'achieve':
    //     const mustAchieveRelationsForNotTookExercises = ExerciseService.getChapterMustAchieveExercicesWhereNotTookRelations(this.player, this.chapter);
    //     this.exercise = mustAchieveRelationsForNotTookExercises.length ? mustAchieveRelationsForNotTookExercises[0].node : null;
    //     break;
    //   case 'extra':
    //     const testsRelationsForNotTookExercises = ExerciseService.getChapterTeachesNotionTestsExercicesWhereNotTookRelations(this.player, this.chapter);
    //     this.exercise = testsRelationsForNotTookExercises.length ? testsRelationsForNotTookExercises[0].node : null;
    //     break;
    //   case 'random':
    //     const testsRelations = ExerciseService.getChapterTeachesNotionTestsExercices(this.player, this.chapter);
    //     if (testsRelations.length) this.exercise = testsRelations[random(0, testsRelations.length - 1)].node;
    //     else this.exercise = null;
    //     break;
    //   default:
    //     this.exercise = null;
    // }
  }


  async onCompleteExercise(_event: { success: boolean }) {
    const player = cloneDeep(this.player);
    const exercise = cloneDeep(this.exercise);
    const chapter = cloneDeep(this.chapter);

    if (!player) throw Error('this.player is undefined');
    if (!exercise) throw Error('this.exercise is undefined');
    if (!chapter) throw Error('this.chapter is undefined');

    const notions: NotionNode[] = chapter.relTeachesNotionsConnection.edges.map(rel => rel.node);
    await ExerciseService.exerciseCompleted(player, notions, exercise, _event.success);

    // Create ENDED relation if there are no exercises left to achieve (ie there are all TOOK)
    const mustAchieveRelationsForNotTookExercises = getChapterMustAchieveExercicesWhereNotTookRelations(player, chapter);
    console.debug(0, 'onCompleteExercise', mustAchieveRelationsForNotTookExercises.length);
    if (!mustAchieveRelationsForNotTookExercises.length) ExerciseService.chapterCompleted(player, chapter);

    if (_event.success) {
      this.sessionXp += exercise.xp;
      this.succeedExercisesCount++;
    }
    this.completedExercisesCount++;
    this.sessionScore = Math.floor(this.succeedExercisesCount / this.completedExercisesCount * 100);

    // If max exercices per session count is reach : terminate session
    if (this.completedExercisesCount >= SESSION_MAX_EXERCICES) return this.terminateSession();

    // Show  encouragement if needed
    if (this.completedExercisesCount % SESSION_NUMBER_EXERCICES_FOR_ENCOURAGEMENTS === 0) this.showEncouragement = true;

    // fetch new exercise
    const newExercise = this.getExercise();
    // unlikely but in case (ie no chapter exercise
    if (!newExercise) return this.terminateSession();

    this.exercise = newExercise;
  }


  onSessionEncouragementContinue(): void {
    this.showEncouragement = false;
  }

  onSessionScoreContinue(): Promise<Route> {
    this.showScore = false;
    return this.$navigate({name: 'Advancement'});
    // this.showEncouragement = false;
  }

  terminateSession() {
    this.showScore = true;
  }
}















































import NavBar from '@/components/NavBar.component.vue';
import {EndedRelation} from '@/models/ended.relation';
import {MustPlayRelationExtended} from '@/models/mustPlay.relation';
import {playerFetchQuery, PlayerNode, playerUpdateQuery} from '@/models/player.node';
import {trainingFetchQuery, TrainingChaptersNode} from '@/models/training.node';
import {Localization, localizationQuery, LocalizationService} from '@/services/localization.service';
import AdvancementPath from '@/views/advancement/AdvancementPath.component.vue';
import {merge} from 'lodash';
import {Component, Vue} from 'vue-property-decorator';


@Component({
  name: 'Advancement',
  components: {
    AdvancementPath,
    NavBar,
  },
  apollo: {
    player: {
      query: playerFetchQuery,
      fetchPolicy: 'cache-first',
      // variables() {
      // return {
      // email: getAuth().currentUser?.email,
      // }
      // },
      update: data => data?.players?.length ? data?.players[0] : undefined,
    },
    localizations: {
      query: localizationQuery,
      fetchPolicy: 'cache-only',
      result(ApolloQueryResult, key) {
        this.trainingsLocalizationsLoaded = LocalizationService.isTrainingLocalizationsLoaded()
      }
    },
    // result(ApolloQueryResult, key) {
    //   console.debug(0, 'App.vue : Apollo : localizationQuery');
    //   // this.localizationsPollInterval = 0;
    // }
    // },
    // trainings: {
    //   query: trainingsFetchQuery,
    //   fetchPolicy: 'cache-first',
    // },
    // currentExercise: {
    //   query: currentExerciseQuery, // local client query
    //   pollInterval: 5000,  // reactive fix
    //   // error: (error: ApolloError, vm: any) => vm.skipQuery = true,
    //   // skip() {
    //   //   return this.skipQuery;
    //   // },
    // },
    // currentNotion: {
    //   query: currentNotionQuery, // local client query
    //   pollInterval: 5000, // reactive fix
    // },
    training: {
      query: trainingFetchQuery,
      fetchPolicy: 'cache-first',
      update: data => data?.trainings?.length ? data?.trainings[0] : undefined,
      variables() {
        // Use vue reactive properties here
        return {
          trainingId: this.player?.currentTrainingId,
        }
      },
      skip() {
        return !this.player?.currentTrainingId
      },
    },
  }
})
export default class Advancement extends Vue {
  player: PlayerNode | undefined;
  training: TrainingChaptersNode | undefined;
  // currentNotion: NotionNode | undefined;
  // currentExercise: ExerciseNode | undefined;
  // trainings: TrainingNode[] = [];
  localizations: Localization | undefined;
  trainingsLocalizationsLoaded = false;


  created() {
    this.trainingsLocalizationsLoaded = LocalizationService.isTrainingLocalizationsLoaded()
    // this.$root.$on('onLocalizationStateChange',
    //     () => this.trainingsLocalizationsLoaded = LocalizationService.isTrainingLocalizationsLoaded());
  }


  mounted() {
    this.$root.$emit('navigationEvent', {view: 'advancement', color: 'primary', title: 'SOON'});
  }


  async onClickMustPlayRelation(_event: { mustPlayRelation: MustPlayRelationExtended, endedRelation: EndedRelation }): Promise<any> {
    console.debug(0, 'onClickMustPlayRelation', _event);
    if (!this.player) return Promise.reject('onClickMustPlayRelation : player is undefined');

    // -- savePlayerProgress
    const playerUpdateInput = {
      currentChapterId: _event.mustPlayRelation.node._id
    }
    // optimisticResponse : no 'await' and start xhr request for chapter sync in same time
    this.$apollo.mutate({
      mutation: playerUpdateQuery,
      variables: {
        playerId: this.player._id,
        playerUpdateInput
      },
      optimisticResponse: {
        updatePlayers: {
          __typename: 'UpdatePlayersMutationResponse',
          players: [
            merge(this.player, playerUpdateInput)
          ],
        }
      },
    })

    return this.$navigate({name: 'Session'});
    // // if chapter not achieved
    // if (_event.mustPlayRelation.haveExerciseToAchieve) return this.$navigate({name: 'Session', params: {exercisesMode: 'achieve'}});
    // // if chapter achieved
    // else {
    //   const testsWhereNotTookRels: TestsRelation[] = ExerciseService.getChapterTeachesNotionTestsExercicesWhereNotTookRelations(this.player, _event.mustPlayRelation.node);
    //   // if we have chapter notions exercises to test but not took
    //   if (testsWhereNotTookRels.length) return this.$navigate({name: 'Session', params: {exercisesMode: 'extra'}});
    //   // if we have chapter notions exercises to test but not took
    //   else {
    //     // TODO : alert
    //     return this.$navigate({name: 'Session', params: {exercisesMode: 'random'}});
    //   }
    // }
  }
}

import StoreService from '@/services/store.service';
import Advancement from '@/views/advancement/Advancement.vue';
import Login from '@/views/authentication/Login.vue';
import Password from '@/views/authentication/Password.vue';
import Profile from '@/views/profile/Profile.vue';
import Questions from '@/views/questions/Questions.vue';
import Session from '@/views/session/Session.vue';
import Stats from '@/views/stats/Stats.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';


Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/advancement',
    },
    {
        path: '/authentication/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/authentication/password',
        name: 'Password',
        component: Password,
    },
    {
        path: '/advancement',
        name: 'Advancement',
        component: Advancement,
        meta: {
            authRequiredGuard: true,
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            authRequiredGuard: true,
        },
    },
    {
        path: '/stats',
        name: 'Stats',
        component: Stats,
        meta: {
            authRequiredGuard: true,
        },
    },
    {
        path: '/questions',
        name: 'Questions',
        component: Questions,
        meta: {
            authRequiredGuard: true,
        },
    },
    {
        path: '/session',
        name: 'Session',
        component: Session,
        meta: {
            authRequiredGuard: true,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    routes
});


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.authRequiredGuard)) {
        if (StoreService.states.user?.email) next();
        else {
            console.debug(2, 'Guard : User is not authenticated');
            next({path: '/login', query: to.query});
        }
    }
    else {
        next();
    }
});


export default router;

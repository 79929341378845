import {ChapterLiteNode, ChapterNode} from '@/models/chapter.node';
import {DbObject} from '@/types/neo4j';
import gql from 'graphql-tag';

export interface EndedDbRelation extends DbObject {
}


export interface EndedRelation extends  EndedDbRelation {
    node: ChapterNode;
}


export interface EndedLiteRelation extends  EndedDbRelation {
    node: ChapterLiteNode;
}

export const endedFragment = `
    _id
    _ownerId
    _createdAt
    _updatedAt
`;


export const endedUpdateOrCreateByIdsQuery = gql`
    mutation endedUpdateOrCreateByIdsQuery (
        $playerId: ID!,
        $chapterId: ID!,
        $relCreateInput: RelEndedCreateInput!
    ) {
        updatePlayers(
            where: { _id: $playerId },
            connect: {
                relEndedChapters: [
                    {
                        where: {
                            node: {
                                _id: $chapterId
                            }
                        },
                        edge: $relCreateInput
                    }
                ]
            }) {
            players {
                _id

                relEndedChaptersConnection {
                    edges {
                        ${endedFragment}

                        node {
                            _id
                        }
                    }
                }
            }
        }
    }
`;

import {chapterFragment} from '@/models/chapter.node';
import {conceivesFragment, ConceivesNotionsRelation} from '@/models/conceives.relation';
import {endedFragment, EndedRelation} from '@/models/ended.relation';
import {exerciseFragment} from '@/models/exercise.node';
import {instructsFragment} from '@/models/instructs.relation';
import {knowsFragment, KnowsRelation} from '@/models/knows.relation';
import {managedPlayerFragment} from '@/models/managedPlayer.node';
import {managesFragment, ManagesLiteRelation, ManagesRelation} from '@/models/manages.relation';
import {mustTrainFragment, MustTrainRelation} from '@/models/mustTrain.relation';
import {notionFragment} from '@/models/notion.node';
import {ratesFragment, RatesRelation} from '@/models/rates.relation';
import {tookFragment, TookRelation} from '@/models/took.relation';
import {trainedForFragment, TrainedForNotionsRelation, TrainedForRelation} from '@/models/trainedFor.relation';
import {trainingFragment} from '@/models/training.node';
import {DbObject} from '@/types/neo4j';
import gql from 'graphql-tag';


export interface PlayerDbNode extends DbObject {
    email: string

    firstname: string
    lastname: string
    pseudo: string
    avatarURL: string
    lang: string

    currentLevel: number
    currentXP: number

    appTimeSpent: number
    gameTimeSpent: number

    currentTrainingId: string
    currentChapterId: string

    lastExerciseDate: Date
    lastChapterDate: Date
    graduallyUnlockChapterDays?: number
}


export interface PlayerLiteNode extends DbObject, PlayerDbNode {
}


export interface PlayerNode extends DbObject, PlayerDbNode {
    relTrainedForTrainingConnection: {
        edges: TrainedForRelation[]
    }
    relMustTrainExerciseConnection: {
        edges: MustTrainRelation[]
    }
    relTookExerciseConnection: {
        edges: TookRelation[]
    }
    relKnowsNotionsConnection: {
        edges: KnowsRelation[]
    }
    relRatesChaptersConnection: {
        edges: RatesRelation[]
    }
    relEndedChaptersConnection: {
        edges: EndedRelation[]
    }
    relManagesPlayersConnection: {
        edges: ManagesLiteRelation[]
    }
}


export interface PlayerManagesPlayersNode extends PlayerDbNode {
    relManagesPlayersConnection: {
        edges: ManagesRelation[]
    }
}


export interface PlayerConceivedTrainingsNotionsNode extends DbObject, PlayerDbNode {
    // relTrainedForTrainingConnection: {
    //     edges: TrainedForNotionsRelation[]
    // }
    relConceivesTrainingConnection: {
        edges: ConceivesNotionsRelation[]
    }
}


export interface PlayerTrainedForTrainingsNotionsNode extends DbObject, PlayerDbNode {
    relTrainedForTrainingConnection: {
        edges: TrainedForNotionsRelation[]
    }
}


export const playerFragment = `
        _id
        _ownerId
        _createdAt
        _updatedAt

        email
        
        firstname
        lastname
        pseudo
        avatarURL
        lang
        
        currentLevel
        currentXP
        
        appTimeSpent
        gameTimeSpent
        
        currentTrainingId
        currentChapterId
        
        lastExerciseDate
        lastChapterDate
        graduallyUnlockChapterDays
`;


// export const playerFetchLiteQuery = gql`
//     query playerFetchLiteQuery(
//         $email : String!
//     ) {
//         players(where: { email: $email }) {
//             ${playerFragment}
//         }
//     }
// `;


export const playerFetchAllLiteQuery = gql`
    query playerFetchAllLiteQuery {
        players {
            ${playerFragment}
        }
    }
`;


export const playerFetchLocalizationQuery = gql`
    query playerFetchLocalizationQuery(
        $email : String!
    ) {
        players(where: { email: $email }) {
            _id
            email
            lang
           
              relTrainedForTrainingConnection {
                edges {
                    _id

                    node {
                        _id
                        name
                    }
                }
            }
        }
    }
`;


export const playerFetchTrainingQuery = gql`
    query playerFetchTrainingQuery(
        $email : String!
    ) {
        players(where: { email: $email }) {
            _id
            currentTrainingId
            currentChapterId
        }
    }
`;

// export const playerFetchChapterQuery = gql`
//     query playerFetchChapterQuery(
//         $email : String!
//     ) {
//         players(where: { email: $email }) {
//             _id
//             currentChapterId
//         }
//     }
// `;


export const playerFetchLiteQuery = gql`
    query playerFetchQuery(
        $email : String!
    ) {
        players(where: { email: $email }) {
            ${playerFragment}
        }
    }
`;


export const playerFetchQuery = gql`
    query playerFetchQuery(
        $email : String!
    ) {
        players(where: { email: $email }) {
            ${playerFragment}

            relTrainedForTrainingConnection {
                edges {
                    ${trainedForFragment}

                    node {
                        ${trainingFragment}
                    }
                }
            }

            relMustTrainExerciseConnection {
                edges {
                    ${mustTrainFragment}

                    node {
                        ${exerciseFragment}
                    }
                }
            }

            relTookExerciseConnection {
                edges {
                    ${tookFragment}

                    node {
                        ${exerciseFragment}
                    }
                }
            }

            relKnowsNotionsConnection {
                edges {
                    ${knowsFragment}

                    node {
                        ${notionFragment}
                    }
                }
            }

            relRatesChaptersConnection {
                edges {
                    ${ratesFragment}

                    node {
                        ${chapterFragment}
                    }
                }
            }


            relEndedChaptersConnection {
                edges {
                    ${endedFragment}

                    node {
                        ${chapterFragment}
                    }
                }
            }

            relManagesPlayersConnection {
                edges {
                    ${managesFragment}

                    node {
                        ${managedPlayerFragment}
                    }
                }
            }

        }
    }
`;


// relManagedByPlayersConnection {
//     edges {
//         ${managesFragment}
//
//         node {
//             ${playerFragment}
//         }
//     }
// }


export const playerFetchManagedPlayersQuery = gql`
    query playerFetchManagedPlayersQuery(
        $email : String!
    ) {
        players(where: { email: $email }) {
            ${playerFragment}

            relManagesPlayersConnection {
                edges {
                    ${managesFragment}

                    node {
                        ${managedPlayerFragment}
                        
                        relTookExerciseConnection {
                            edges {
                                ${tookFragment}
                            }
                        }
                       

                        relEndedChaptersConnection {
                            edges {
                                ${endedFragment}

                                node {
                                    ${chapterFragment}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;


export const playerFetchConceivedTrainingNotionsQuery = gql`
    query playerFetchConceivedTrainingNotionsQuery(
        $email : String!
    ) {
        players(where: { email: $email }) {
            ${playerFragment}

            relConceivesTrainingConnection {
                edges {
                    ${conceivesFragment}

                    node {
                        ${trainingFragment}
                        
                        relInstructsNotionsConnection {
                            edges {
                                ${instructsFragment}
            
                                node {
                                    ${notionFragment}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;


export const playerFetchTrainedForTrainingNotionsQuery = gql`
    query playerFetchTrainingNotionsQuery(
        $email : String!
    ) {
        players(where: { email: $email }) {
            ${playerFragment}

            relTrainedForTrainingConnection {
                edges {
                    ${trainedForFragment}

                    node {
                        ${trainingFragment}
                        
                         relInstructsNotionsConnection {
                            edges {
                                ${instructsFragment}
            
                                node {
                                    ${notionFragment}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;


export const playerUpdateQuery = gql`
    mutation playerUpdateQuery(
        $playerId: ID!, $playerUpdateInput: PlayerUpdateInput!
    ) {
        updatePlayers(
            where: { _id: $playerId }
            update: $playerUpdateInput
        ) {
            players {
                ${playerFragment}
            }
        }
    }
`;

import {exerciseFragment} from '@/models/exercise.node';
import {mustAchieveFragment, MustAchieveRelation} from '@/models/mustAchieve.relation';
import {notionFragment} from '@/models/notion.node';
import {teachesFragment, TeachesRelation} from '@/models/teaches.relation';
import {testsFragment} from '@/models/tests.relation';
import {DbObject} from '@/types/neo4j';
import gql from 'graphql-tag';


export interface ChapterDbNode extends DbObject {
    name: string
}


export interface ChapterLiteNode extends ChapterDbNode {
}


export interface ChapterNode extends ChapterDbNode {
    relTeachesNotionsConnection: {
        edges: TeachesRelation[]
    }
    relMustAchieveExercisesConnection: {
        edges: MustAchieveRelation[]
    }
}


export const chapterFragment = `
    _id
    _ownerId
    _createdAt
    _updatedAt

    name
`;


export const chapterFetchQuery = gql`
    query chapterFetchQuery(
        $chapterId: ID!
    ) {
        chapters(where: { _id: $chapterId }) {
            ${chapterFragment}
            
           relMustAchieveExercisesConnection {
                edges {
                    ${mustAchieveFragment}

                    node {
                        ${exerciseFragment}
                    }
                }
            }
            
            relTeachesNotionsConnection {
                edges {
                    ${teachesFragment}

                    node {
                        ${notionFragment}

                        relTestsExercisesConnection {
                            edges {
                                ${testsFragment}

                                node {
                                    ${exerciseFragment}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

import {ChapterNode} from '@/models/chapter.node';
import {DbObject} from '@/types/neo4j';

export interface MustPlayDbRelation extends DbObject {
    order: number
}

export interface MustPlayRelation extends DbObject, MustPlayDbRelation {
    node: ChapterNode
}

export interface MustPlayRelationExtended extends MustPlayRelation {
    haveExerciseNotAchievedYet?: number
}


export const mustPlayFragment = `
    _id
    _ownerId
    _createdAt
    _updatedAt
    
    order
`;

import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                background: '#E1E9F7',
                primary: '#1D2742',
                secondary: '#E1E9F7',
                // tertiary: '',
                accent: '#F4A891',  //'#414141',
                error: '#FF5252',
                success: '#5B7749',
                warning: '#ef6c00',
                info: '#2196F3',
            },
        },
        options: {customProperties: true},
    },
    icons: {
        iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
});

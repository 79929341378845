import {ChapterNode} from '@/models/chapter.node';
import {DbObject} from '@/types/neo4j';
import gql from 'graphql-tag';

export interface RatesDbRelation extends DbObject {
    score: number
    comment: string
}

export interface RatesRelation extends DbObject, RatesDbRelation {
    node: ChapterNode;
}

export const ratesFragment = `
    _id
    _ownerId
    _createdAt
    _updatedAt
    
    score
    comment
`;


export const ratesUpdateOrCreateByIdsQuery = gql`
    mutation ratesUpdateOrCreateByIdsQuery (
        $playerId: ID!,
        $chapterId: ID!,
        $relCreateInput: RelRatesCreateInput!
    ) {
        updatePlayers(
            where: { _id: $playerId },
            connect: {
                relRatesChapters: [
                    {
                        where: {
                            node: {
                                _id: $chapterId
                            }
                        },
                        edge: $relCreateInput
                    }
                ]
            }) {
            players {
                _id

                relRatesChaptersConnection {
                    edges {
                        ${ratesFragment}

                        node {
                            _id
                        }
                    }
                }
            }
        }
    }
`;

import App from '@/App.vue';
import vuetify from '@/plugins/vuetify.plugin';
import router from '@/router';
import {apolloProvider} from '@/services/apollo.service';
import {initializeFirebase} from '@/services/firebase.service';
import {LocalizationService} from '@/services/localization.service';
import {VueExtended, VueService} from '@/services/vue.service';
import axios from 'axios';
import {isObject} from 'lodash';
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import VueApollo from 'vue-apollo';
import VueAxios from 'vue-axios';
import {RawLocation, Route} from 'vue-router/types/router';


if (process.env.NODE_ENV === 'development') {
    console.debug = (_level: 0 | 1 | 2, ...args: any[]) => {
        const firstArg = args.shift();

        if (isObject(firstArg)) console.log(firstArg);
        else console.log(`%c ${firstArg}`, ['color: #58a6ff', 'color: #e99952', 'color: #fb7971'][_level], ...args);
    }

    console.debug(1, 'Development Mode');
}
else {
    console.debug = (...args: any[]) => null;
    console.log = (...args: any[]) => null;
}


Vue.config.productionTip = false;

Vue.prototype.$sync = function (key: string, value: any) {
    this.$emit(`update:${key}`, value);
};

Vue.prototype.$scrollToElement = function (refName: string) {
    try {
        const els = this.$refs[refName];
        // @ts-ignores
        if (els && els[0]) els[0].scrollIntoView({behavior: 'smooth'});
        // @ts-ignores
        else if (els) els.scrollIntoView({behavior: 'smooth'});
    } catch (e) {
        console.warn(e);
    }
};

Vue.prototype.$loc = LocalizationService.getLocale;

Vue.prototype.$navigate = function (_location: RawLocation): Promise<Route> {
    this.$root.$emit('displayAppLoader', {value: true});

    // Rq : Use defer because StoreService.onLocalizationStateChange takes time
    // return defer(() => {
    return this.$router.push(_location)
        .then(() => this.$root.$emit('displayAppLoader', {value: false}))
        .catch((error: Error) => {
            if (error.name !== 'NavigationDuplicated') console.error(error);
            this.$root.$emit('displayAppLoader', {value: false});
            // this.$root.$emit('toastEvent', {text: this.$loc('Error.navigate'), closeTimeout: 0});
        })
    // });
};

// Set Vue Plugin
Vue.use(VueAxios, axios);

// Set Apollo Plugin
Vue.use(VueApollo);

// Initialize Firebase
initializeFirebase();

// Add ApexCharts
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts);

// Initialize Vue
console.debug(1, 'Main : Mount Vue instance');
const vue = new Vue({
    router,
    vuetify,
    apolloProvider,
    render: h => h(App),
});
vue.$mount('#vue-app');

// Init VueService
VueService.prototype.$vue = vue as VueExtended;

// // Start time tracking
// TimeTrackingService.initialize();
//
// // Init UserService
// StoreService.initialize();
//
// // Init UserService
// UserService.initialize();

// Start Application
console.debug(1, 'Main : Start Application');
